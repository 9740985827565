import React, { Fragment } from 'react'
import '../styles/css/landing.css'
import { Link } from 'react-router-dom'
import SliderCarousel from './SliderCarousel'
import Categories from './Categories'
import { ReactComponent as CustomerCare } from '../assets/svg/customer-care.svg'
import { ReactComponent as DownloadIc } from '../assets/svg/download.svg'
import { ReactComponent as Return } from '../assets/svg/return.svg'

const Landing: React.FC = () => {
  return (
    <Fragment>
      <div className="landing">
        <div className="container">
          <div className="landLayout">
            <div className="slideshow">
              <SliderCarousel />
            </div>
            <div className="landhther">
              <div className="landHigh">
                <div className="lndhgone">
                  <CustomerCare className="cusomercare" />
                  <div className="lndonetxts">
                    <h3>help center</h3>
                    <p>guide to better site usage</p>
                  </div>
                </div>
                <div className="lndhgone">
                  <Return className="cusomercare" />
                  <div className="lndonetxts">
                    <h3>Easy Return</h3>
                    <p>quick refund and product exchange</p>
                  </div>
                </div>
                <div className="lndhgone">
                  <DownloadIc className="cusomercare" />
                  <div className="lndonetxts">
                    <h3>Download app</h3>
                    <p>get the best experience from our app</p>
                  </div>
                </div>
                <Link className="linktoallproducts" to="/catalog">
                  <button className="startbrowsing">start shopping</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Categories />
    </Fragment>
  )
}

export default Landing
