import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/sellwithus.css'
import { ReactComponent as SellWithUsIc } from '../assets/svg/undraw/undraw_credit_card_re_blml.svg'
import { ReactComponent as Xmark } from '../assets/svg/x-mark.svg'
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import Footer from '../components/Footerrr'

const SellWithUs: React.FC = () => {
  const [swureg, setSwuReg] = useState(false)
  const [swulogin, setSwuLogin] = useState(false)

  // check if user is logged in to the system
  const isuserloggedin = useSelector((state: any) => state.islogged)
  return (
    <Fragment>
      <Helmet>
        <title>Sell With Us | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="sellwithus">
        <div className="sellwithuscontainer">
          <div className="swulanding">
            <div className="swubreadcrumb">
              <h1>Sell on Gro & MedMart</h1>
              <button
                className="swusignupbtn"
                onClick={() => {
                  setSwuLogin(true)
                }}
              >
                sign in
              </button>
            </div>
            <div className="swulndingmain">
              <div className="swulefttexts">
                <h1>Become a</h1>
                <h1>Gro & MedMart Seller</h1>
                <p>
                  The fastest-growing and preferred acquisition channel for over
                  half our multichannel sellers.
                </p>
                <button
                  className="swusignupbtn"
                  onClick={() => {
                    setSwuReg(true)
                  }}
                >
                  sign up
                </button>
                <br />
                <span>KES 2500 a month + selling fees</span>
              </div>
              <div className="swurightimage">
                <SellWithUsIc className="swusvgic" />
              </div>
            </div>
            <div className="swubannerbtm">
              <div className="swubannerwrapper">
                <h1>Start selling today</h1>
                <p>
                  The fastest-growing and preferred acquisition channel for over
                  half our multichannel sellers.
                </p>
                <button
                  className="swusignupbtn"
                  onClick={() => {
                    setSwuReg(true)
                  }}
                >
                  sign up
                </button>
                <br />
                <span>KES 2500 a month + selling fees</span>
              </div>
            </div>
          </div>
          <div
            className={
              swureg ? 'sellwithusregistershop' : 'sellwithusregistershop hide'
            }
            onClick={() => {
              setSwuReg(false)
            }}
          >
            <div className="regshopform">
              <form className="regformsh">
                <div
                  className="regformshwrapper"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Xmark
                    className="xmarkicswu"
                    onClick={() => {
                      setSwuReg(false)
                    }}
                  />
                  <h1>Register your digital shop</h1>
                  <div className="shreg-group">
                    <label htmlFor="Name of the shop">
                      Name of the shop (Display Name) <span>*</span>
                    </label>
                    <br />
                    <input type="text" className="shfrminput" />
                    <p>
                      Choose a unique name for your online shop: It is forbidden
                      to use a registered trademark in your shop name without
                      the brand authorisation.
                    </p>
                  </div>
                  <div className="shreg_group_flexer">
                    <div className="shreg-group">
                      <label htmlFor="Name of the shop">
                        Account Manager Full Name <span>*</span>
                      </label>
                      <br />
                      <input type="text" className="shfrminput" />
                    </div>
                    <div className="shreg-group">
                      <label htmlFor="Name of the shop">
                        Account manager phone number <span>*</span>
                      </label>
                      <br />
                      <input type="number" className="shfrminput" />
                    </div>
                  </div>
                  {/* detect logged in and use current email and password */}
                  {isuserloggedin ? (
                    <div>
                      <h3>detected you are logged in</h3>
                    </div>
                  ) : (
                    <div>
                      <div className="shreg-group">
                        <label htmlFor="Name of the shop">
                          Account manager Email Address <span>*</span>
                        </label>
                        <br />
                        <input type="text" className="shfrminput" />
                      </div>
                      <div className="shreg-group">
                        <label htmlFor="Name of the shop">
                          Password <span>*</span>
                        </label>
                        <br />
                        <input type="password" className="shfrminput" />
                      </div>
                      <div className="shreg-group">
                        <label htmlFor="Name of the shop">
                          Confirm Password <span>*</span>
                        </label>
                        <br />
                        <input type="password" className="shfrminput" />
                      </div>
                    </div>
                  )}
                  <div className="btnwrapswucont">
                    <button className="regswubtn" type="submit">
                      sign up
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            className={
              swulogin ? 'sellwithusloginshop' : 'sellwithusloginshop hide'
            }
            onClick={() => {
              setSwuLogin(false)
            }}
          >
            <div className="regshopform">
              <form className="regformsh">
                <div
                  className="regformshwrapper"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Xmark
                    className="xmarkicswu"
                    onClick={() => {
                      setSwuLogin(false)
                    }}
                  />
                  <h1>Login your digital shop</h1>
                  <div className="shreg-group">
                    <label htmlFor="Name of the shop">
                      Email Address <span>*</span>
                    </label>
                    <br />
                    <input type="text" className="shfrminput" />
                  </div>
                  <div className="shreg-group">
                    <label htmlFor="Name of the shop">
                      Password <span>*</span>
                    </label>
                    <br />
                    <input type="password" className="shfrminput" />
                  </div>
                  <div className="btnwrapswucont">
                    <button className="regswubtn" type="submit">
                      sign in
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default SellWithUs
