import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/ratings.css'

const Ratings = ({ rating }: { rating: number }) => {
  const [progressBar, setProgressBar] = useState<any>({
    five: { '--progress-width': `${0}%` },
    four: { '--progress-width': `${0}%` },
    three: { '--progress-width': `${0}%` },
    two: { '--progress-width': `${0}%` },
    one: { '--progress-width': `${0}%` },
  })

  useEffect(() => {
    setProgressBar({
      five: { '--progress-width': `${90}%` },
      four: { '--progress-width': `${30}%` },
      three: { '--progress-width': `${25}%` },
      two: { '--progress-width': `${10}%` },
      one: { '--progress-width': `${30}%` },
    })
  }, [])

  return (
    <Fragment>
      <div className="ratings">
        <div className="ratings_container">
          <div className="ratings_wrapper">
            <div className="ratings_left">
              <span>4.2</span>
              <i data-star={rating}></i>
              <p className="ratings_no_of_reviews">365k reviews</p>
            </div>
            <div className="ratings_right">
              <ul className="indicator_figure">
                <li className="rating_indicator">
                  <span>5</span>
                  <div
                    className="rating_indicator_bar"
                    style={progressBar.five}
                  ></div>
                </li>
                <li className="rating_indicator">
                  <span>4</span>
                  <div
                    className="rating_indicator_bar"
                    style={progressBar.four}
                  ></div>
                </li>
                <li className="rating_indicator">
                  <span>3</span>
                  <div
                    className="rating_indicator_bar"
                    style={progressBar.three}
                  ></div>
                </li>
                <li className="rating_indicator">
                  <span>2</span>
                  <div
                    className="rating_indicator_bar"
                    style={progressBar.two}
                  ></div>
                </li>
                <li className="rating_indicator">
                  <span>1</span>
                  <div
                    className="rating_indicator_bar"
                    style={progressBar.one}
                  ></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Ratings
