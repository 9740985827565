import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Maintainance from '../components/Maintainance'
import Header from '../components/Header'
import Footer from '../components/Footerrr'

const DisputeResCenter: React.FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Dispute Resolution Center | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <Maintainance />
      <Footer />
    </Fragment>
  )
}

export default DisputeResCenter
