type Action = {
  type: string
  payload?: {}
}
const currency = (state: {} = [], action: Action) => {
  switch (action.type) {
    case 'SETCURRENCY':
      return action.payload
    default:
      return state
  }
}

export default currency
