import React, { Fragment, useState, useEffect } from 'react'
import { ReactComponent as AngleDown } from '../assets/svg/angle-down.svg'
import { ReactComponent as Store } from '../assets/svg/store.svg'
import '../styles/css/dropdown.css'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import api from '../api/axios'
import { toast } from 'react-toastify'

const DropDown = () => {
  const [dropdata, setDropData] = useState<any[]>([])
  const [dropdown, setDropDown] = useState(false)
  const [isopen, setIsopen] = useState(dropdata[0]?.title)
  const [clickclose, setClickclose] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!clickclose) {
      setDropDown(false)
    }
  }, [clickclose])

  const getdropdata = async () => {
    setLoading(true)
    const res = await api('GET', 'categories/all')
    setLoading(false)
    if (res.status >= 200 && res.status <= 299) {
      setDropData(res.data)
    } else {
      toast(res.data.message, { type: 'error' })
    }
  }

  useEffect(() => {
    getdropdata()
  }, [])
  return (
    <Fragment>
      <div className="dropdown">
        <div
          className="drcat"
          onClick={() => {
            setDropDown(!dropdown)
            setClickclose(true)
          }}
        >
          <p>categories</p>
          <span>
            <AngleDown className={dropdown ? 'angdown angup' : 'angdown'} />
          </span>
        </div>
        <div
          className={clickclose ? (dropdown ? 'dropnav' : 'hide') : 'hide'}
          onClick={() => {
            setDropDown(false)
          }}
        >
          <div className="drpanglePointer"></div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div
              className="drpnavcont"
              onClick={(e) => {
                setDropDown(true)
                e.stopPropagation()
              }}
            >
              <div className="drpLeft">
                <ul className="drpnavigation">
                  {dropdata.map((i) => {
                    return (
                      <li className="drpleftlink" key={uuidv4()}>
                        <Store className="drpleftNavIc" />
                        <p onMouseOver={() => setIsopen(i.title)}>{i.title}</p>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="drpRight">
                {dropdata
                  .filter((it) => it.title === isopen)
                  .map((i) => {
                    return (
                      <div className="drpRSections" key={uuidv4()}>
                        {i.sub_categories.map((t: any) => {
                          return (
                            <div className="drpsectionslists" key={uuidv4()}>
                              <h2>{t.sub_title}</h2>
                              <ul>
                                {t.categories.map((r: any) => {
                                  return (
                                    <li
                                      key={
                                        r.mini_title +
                                        new Date().getTime().toString(36) +
                                        Math.random().toString(36).slice(2)
                                      }
                                      onClick={() => {
                                        setClickclose(false)
                                        setDropDown(false)
                                      }}
                                    >
                                      <Link
                                        to={`/catalog?category=${r.mini_slug}`}
                                      >
                                        <p>{r.mini_title}</p>
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default DropDown
