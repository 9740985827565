interface ValidationResult {
  isvalid: boolean
  cleanedNumber?: string
  message?: string
}
export const validateAndCleanPhoneNumber = (
  phoneNumber: string
): ValidationResult => {
  let cleanedNumber = phoneNumber.replace(/-/g, '')
  if (!/^\d+$/.test(cleanedNumber)) {
    return { isvalid: false, message: 'phone number must only be numbers' }
  }
  cleanedNumber = cleanedNumber.replace(/\D/g, '')
  if (cleanedNumber.length < 9 || cleanedNumber.length > 12) {
    return { isvalid: false, message: 'invalid phone number length' }
  }
  let finalNumber = cleanedNumber
  if (cleanedNumber.length === 10 && cleanedNumber.charAt(0) !== '0') {
    console.log(cleanedNumber)
    return {
      isvalid: false,
      message: 'phone number should start with a 0 or 254',
    }
  } else if (cleanedNumber.length === 10) {
    finalNumber = cleanedNumber.slice(1)
  }
  if (cleanedNumber.startsWith('254')) {
    finalNumber = cleanedNumber.substring(3)
  }
  if (finalNumber.length !== 9) {
    return { isvalid: false, message: 'check your phone number' }
  }
  finalNumber = '254' + finalNumber
  return { isvalid: true, cleanedNumber: finalNumber, message: '' }
}
