import React from 'react'
import '../styles/css/showcaseitem.css'
// import Grocery from '../assets/images/grocery.png'
// import { ReactComponent as Plus } from '../assets/svg/plus.svg'
import { useNavigate } from 'react-router-dom'
import FormatCurrency from '../utils/FormatCurrency'

const ShowcaseItem = ({ product, navigation }: any) => {
  const navigate = useNavigate()
  return (
    <div
      className="showcaseitem_kibz"
      key={product._id}
      onClick={() => {
        window.scrollTo(0, 0)
        navigate(`/product/${product.slug}`)
      }}
    >
      <div className="showcaseitem_kibz_wrapper">
        <div
          className={
            navigation
              ? 'showcaseitem_kibz_top'
              : 'showcaseitem_kibz_top showcaseitem_kibz_top_catalog'
          }
        >
          <div className="showcaseitem_kibz_product_image">
            <div className="showcaseitem_kibz_image_wrapper">
              <img
                className="howcaseitem_kibz_image_overlay"
                src={product.arrimages.images[product.arrimages.thumbnail]}
                loading="lazy"
                alt=""
              />
              <div className="howcaseitem_kibz_image_overlay"></div>
              <img
                className="showcaseitem_kibz_image"
                src={product.arrimages.images[product.arrimages.thumbnail]}
                loading="lazy"
                alt=""
              />
            </div>
          </div>
          {/* <div
            className="showcaseitem_kibz_add_to_cart"
            onClick={() => {
              homeAddToCart(product._id)
            }}
          >
            <Plus className="plus" />
            <p className="showcaseitem_kibz_add_to_cart_texts">add to cart</p>
          </div> */}
          {product.previous_price && (
            <div className="showcaseitem_kibz_discount_percentage">
              <p className="showcaseitem_kibz_add_to_cart_texts">
                {`-${
                  ((parseFloat(product.previous_price) -
                    (product.price[0].$numberDecimal
                      ? parseFloat(product.price[0].$numberDecimal)
                      : parseFloat(product.price[0]))) /
                    parseFloat(product.previous_price)) *
                  100
                }%`}
              </p>
            </div>
          )}
        </div>
        <div className="showcaseitem_kibz_bottom">
          {/* <div className="showcaseitem_kibz_profile">
            <img src={Grocery} alt="" />
          </div> */}
          <div className="showcaseitem_kibz_meta">
            <h3
              className="showcaseitem_kibz_product_name"
              title={product.title}
            >
              {product.title}
            </h3>
            <div className="showcaseitem_kibz_price_wrapper">
              <div className="showcaseitem_kibz_product_price">
                {product.price ? (
                  product.price.length !== 1 ? (
                    <p>
                      <FormatCurrency
                        value={
                          product.price[0].$numberDecimal
                            ? parseFloat(product.price[0].$numberDecimal)
                            : parseFloat(product.price[0])
                        }
                      />{' '}
                      <span className="inform_more_on_smaller_screens">*</span>
                      <span className="minify_on_smaller_screens">
                        -{' '}
                        <FormatCurrency
                          value={
                            product.price[1].$numberDecimal
                              ? parseFloat(product.price[1].$numberDecimal)
                              : parseFloat(product.price[1])
                          }
                        />
                      </span>
                    </p>
                  ) : (
                    <FormatCurrency
                      value={
                        product.price[0].$numberDecimal
                          ? parseFloat(product.price[0].$numberDecimal)
                          : parseFloat(product.price[0])
                      }
                    />
                  )
                ) : (
                  '---'
                )}
              </div>
              {product.previous_price ? (
                <p className="showcaseitem_kibz_product_price_discount">
                  <FormatCurrency value={product.previous_price} />
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowcaseItem
