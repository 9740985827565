import React, { Suspense } from 'react'
import '../styles/css/showcase.css'
import ShowcaseItem from './ShowcaseItem'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'

import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Navigation } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const Showcase = ({
  prods,
  homeAddToCart,
  all = true,
  category = 'products',
  navigation = false,
}: any) => {
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <div className="showcase_content">
        {prods.data.length === 0 ? (
          <div></div>
        ) : !all ? (
          <div className="showcasecont_header">
            <div className="showcasecont_header_wrapper">
              <h1 className="showcasecont_header_title">{category}</h1>
              <div className="showcase_header_see_all">
                <h2>see all</h2>
                <ArrowRight className="showcase_arrow_right" />
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={
            navigation ? 'showcasecont' : 'showcasecont showcasecont_gridify'
          }
        >
          {navigation ? (
            <Swiper
              spaceBetween={5}
              slidesPerView={1}
              navigation={navigation}
              modules={[Navigation]}
              breakpoints={{
                640: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1000: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                1840: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
              }}
              className="catSwiper"
            >
              {prods.data.map((product: any) => (
                <SwiperSlide
                  className="catswipeslide"
                  key={product._id}
                  onClick={() => {
                    window.scrollTo(0, 0)
                  }}
                >
                  <ShowcaseItem
                    key={product._id}
                    product={product}
                    navigation={navigation}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            prods.data.map((product: any) => (
              <ShowcaseItem
                key={product._id}
                product={product}
                navigation={navigation}
              />
            ))
          )}
        </div>
      </div>
    </Suspense>
  )
}

export default Showcase
