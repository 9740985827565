import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/allproducts.css'
import PriceSlider from '../components/PriceSlider'
import { Link, useSearchParams } from 'react-router-dom'
import api from '../api/axios'
import Header from '../components/Header'
import Footer from '../components/Footerrr'
import ShowcaseLoader from '../components/ShowcaseLoader'
import Showcase from '../components/Showcase'
import { useAppSelector } from '../state/hooks'
import { ReactComponent as NotFImage } from '../assets/svg/notfound.svg'

interface Product {
  _id: number
  ProdImage: string
  Name: string
  Price: number
}

const AllProducts = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState({ type: '', message: '' })
  const [prods, setProds] = useState({ type: '', data: [] })
  const [liked, setLiked] = useState<Product[]>([])
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category')
  const search = searchParams.get('search')

  const currency: any = useAppSelector((state) => state.currency)

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const header = document.querySelector('.allproductsleft')
    const scrollTop = window.scrollY
    scrollTop >= 80
      ? header?.classList.add('allproductsleftSticky')
      : header?.classList.remove('allproductsleftSticky')
  }

  useEffect(() => {
    const fetchProds = async () => {
      try {
        setLoading(true)
        const res: any = await api(
          'GET',
          `products/all?category=${category}&search=${search}`,
          {},
          { currency: currency.code ? currency.code : 'usd' }
        )
        setProds({ ...res.data, data: res.data.products })
      } catch (error) {
        console.error('Error fetching products:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchProds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, search, currency.code])

  const homeAddToCart = async (id: number) => {
    try {
      let authToken = sessionStorage.getItem('authToken')
      const res: any = await api('POST', 'cart/add', {
        authToken: authToken,
        _id: id,
        Quantity: 1,
      })
      setResponse(res.data)

      setTimeout(() => {
        setResponse({ type: '', message: '' })
      }, 2000)
    } catch (error) {
      console.error('Error adding to cart:', error)
    }
  }
  return (
    <Fragment>
      <Helmet>
        <title>All Products | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="allproducts">
        <div className="allproductscontainer">
          {response.type === 'success' ? (
            <div className="allproductsinfobox">
              <p>{response.message}</p>
            </div>
          ) : response.type === 'error' ? (
            <div className="allproductserrbox">
              <p>{response.message}</p>
            </div>
          ) : null}
          <div className="allproductswrapper">
            <div className="allproductslayout">
              <div className="allproductsleft allproductsleftSticky">
                <h1>All Products</h1>
                <div className="allprodsec">
                  <h2>categories</h2>
                  <ul className="allprodcatlist">
                    <fieldset
                      id="categories"
                      className="allprodcatlistfieldset"
                    >
                      <div className="all_prod_list_selected_products">
                        <span className="all_prod_list_item">electronics</span>
                        <span className="all_prod_list_item">computing</span>
                        <span className="all_prod_list_item">
                          sound systems
                        </span>
                      </div>
                    </fieldset>
                  </ul>
                </div>
                <div className="allprodsec">
                  <h2>brand</h2>
                  <ul className="allprodcatlist">
                    <li className="allprodlistitem">
                      <input type="checkbox" />
                      <Link to="/">vitron</Link>
                    </li>
                    <li className="allprodlistitem">
                      <input type="checkbox" />
                      <Link to="/">Food</Link>
                    </li>
                  </ul>
                </div>
                <div className="allprodsec">
                  <h2>price range</h2>
                  <PriceSlider />
                </div>
                <div className="allprodsec">
                  <h2>discount percentage</h2>
                  <ul className="allprodcatlist">
                    <fieldset
                      id="categories"
                      className="allprodcatlistfieldset"
                    >
                      <li className="allprodlistitem">
                        <input
                          type="radio"
                          name="categories"
                          className="filterradioselection"
                        />
                        <Link to="/">30% or more</Link>
                      </li>
                      <li className="allprodlistitem">
                        <input
                          type="radio"
                          name="categories"
                          className="filterradioselection"
                        />
                        <Link to="/">20% or more</Link>
                      </li>
                      <li className="allprodlistitem">
                        <input
                          type="radio"
                          name="categories"
                          className="filterradioselection"
                        />
                        <Link to="/">10% or more</Link>
                      </li>
                    </fieldset>
                  </ul>
                </div>
                <div className="allprodsec">
                  <h2>shipped from</h2>
                  <ul className="allprodcatlist">
                    <li className="allprodlistitem">
                      <input type="checkbox" />
                      <Link to="/">shipped from kenya</Link>
                    </li>
                    <li className="allprodlistitem">
                      <input type="checkbox" />
                      <Link to="/">shipped from abroad</Link>
                    </li>
                  </ul>
                </div>
                <div className="allprodsec">
                  <h2>delivery</h2>
                  <ul className="allprodcatlist">
                    <li className="allprodlistitem">
                      <input type="checkbox" />
                      <Link to="/">Wells Fargo</Link>
                    </li>
                    <li className="allprodlistitem">
                      <input type="checkbox" />
                      <Link to="/">Posta Kenya</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="allproductsbrowse">
                <h1>
                  {prods.data.length !== 0
                    ? category && search
                      ? `Showing results for "${search}" in ${category}`
                      : category
                      ? category.replace(/-/g, ' ')
                      : search
                      ? `Showing Results for "${search}"`
                      : ''
                    : ''}
                </h1>
                <div className="allproductsbrowsemobilefilters"></div>
                <div
                  className="showcase allprodshowcase"
                  style={{ margin: '0px !important' }}
                >
                  {loading ? (
                    <ShowcaseLoader />
                  ) : prods.data.length !== 0 ? (
                    <Showcase
                      prods={prods}
                      response={response}
                      liked={liked}
                      homeAddToCart={homeAddToCart}
                      setLiked={setLiked}
                      navigation={false}
                    />
                  ) : (
                    <div className="all_prods_no_results">
                      <div className="all_prods_no_results_wrapper">
                        <div className="all_prods_no_results_box_center">
                          <NotFImage className="no_results_ic" />
                          {category ? (
                            <h3>{`There are no results in the Category ${category.replace(
                              /-/g,
                              ' '
                            )}`}</h3>
                          ) : null}
                          {search ? (
                            <h3>{`There are no results for ${search}`}</h3>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default AllProducts
