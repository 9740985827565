import React, { useState, useRef, useEffect } from 'react'

const OutsideClick = (WrappedComponent) => {
  const Component = (props) => {
    const [open, setOpen] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])

    return (
      <WrappedComponent open={open} setOpen={setOpen} ref={ref} {...props} />
    )
  }

  return Component
}

export default OutsideClick
