const backend = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000/'
    // return 'http://192.168.0.103:8000/'
    //return process.env.REACT_APP_BACKEND_URL
  } else {
    return process.env.REACT_APP_BACKEND_URL
  }
}

export default backend
