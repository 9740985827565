import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/shop.css'
import CoverImage from '../assets/images/laptops.png'
import Profile from '../assets/images/kibet.png'
import { ReactComponent as Store } from '../assets/svg/store.svg'
import { ReactComponent as Bell } from '../assets/svg/bell.svg'
import { ReactComponent as HasgTag } from '../assets/svg/hashtag.svg'
import { ReactComponent as Message } from '../assets/svg/message.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as Rate } from '../assets/svg/rate.svg'
import { ReactComponent as Diputes } from '../assets/svg/shield.svg'
import Header from '../components/Header'
import Footer from '../components/Footerrr'

const Shop: React.FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Shop | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="shop">
        <div className="shopwrapper">
          <div className="shopLayout">
            <div className="shopLeft">
              <ul className="shopLeftNav">
                <li className="shprsidenavlink">
                  <Store className="nvlnkshop" />
                  <p>Shop</p>
                </li>
                <li className="shprsidenavlink">
                  <Bell className="nvlnkshop" />
                  <p>Notifications</p>
                </li>
                <li className="shprsidenavlink">
                  <Message className="nvlnkshop" />
                  <p>Messages</p>
                </li>
                <li className="shprsidenavlink">
                  <HasgTag className="nvlnkshop" />
                  <p>Sales</p>
                </li>
                <li className="shprsidenavlink">
                  <Diputes className="nvlnkshop" />
                  <p>Diputes</p>
                </li>
                <li className="shprsidenavlink">
                  <Rate className="nvlnkshop" />
                  <p>Stats</p>
                </li>
                <li className="shprsidenavlink">
                  <Trash className="nvlnkshop" />
                  <p>Trash</p>
                </li>
              </ul>
            </div>
            <div className="shopRight">
              <div className="shopRTop">
                <div className="shopCoverImage">
                  <img src={CoverImage} alt="" />
                </div>
                <div className="shopProfileImage">
                  <img src={Profile} alt="" />
                </div>
              </div>
              <div className="shopBreadCrumb">
                <div className="crumbLeft">
                  <h1>Rupas Chain Supplies Limited.</h1>
                  <p>@rupas</p>
                </div>
                <div className="crumbRight">
                  <button className="edit_shop_profile">Edit Profile</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Shop
