type Action = {
  type: string
  payload?: string
}
const socket = (state: string = '', action: Action) => {
  switch (action.type) {
    case 'SETSOCKET':
      return action.payload
    default:
      return state
  }
}

export default socket
