import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Xmark } from '../assets/svg/x-mark.svg'
import { ReactComponent as AngleDown } from '../assets/svg/angle-down.svg'
import '../styles/css/homecatdrop.css'

interface Option {
  value: string
  label: string
}

interface HomeCatDropProps {
  placeHolder: string
  options: Option[]
  isMulti: boolean
  isSearchable: boolean
  onChange: (value: Option[] | Option | null) => void
  errStyle?: string
}

const HomeCatDrop: React.FC<HomeCatDropProps> = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  errStyle,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<any>(isMulti ? [] : null)
  const [searchValue, setSearchValue] = useState<string>('')
  const searchRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSearchValue('')
    if (showMenu && searchRef.current) {
      searchRef.current.focus()
    }
  }, [showMenu])

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setShowMenu(false)
      }
    }

    window.addEventListener('click', handler)
    return () => {
      window.removeEventListener('click', handler)
    }
  })

  const handleInputClick = () => {
    setShowMenu(!showMenu)
  }

  const removeOption = (option: any): Option[] => {
    if (!selectedValue || !isMulti) {
      return []
    }

    return selectedValue.filter((o: any) => o.value !== option.value)
  }

  const onTagRemove = (e: React.MouseEvent, option: Option) => {
    e.stopPropagation()
    const newValue: any = removeOption(option)
    setSelectedValue(newValue)
    onChange(newValue)
  }

  const onItemClick = (option: any) => {
    let newValue: any
    if (isMulti) {
      if (
        selectedValue &&
        selectedValue.findIndex((o: any) => o.value === option.value) >= 0
      ) {
        newValue = removeOption(option)
      } else {
        newValue = selectedValue ? [...selectedValue, option] : [option]
      }
    } else {
      newValue = option
    }
    setSelectedValue(newValue)
    onChange(newValue)
  }

  const isSelected = (option: Option): boolean => {
    if (isMulti) {
      return (
        !!selectedValue &&
        selectedValue.filter((o: any) => o.value === option.value).length > 0
      )
    }
    return !!selectedValue && selectedValue.value === option.value
  }

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const getOptions = (): Option[] => {
    if (!searchValue) {
      return options
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    )
  }

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue.map((option: any) => (
            <div key={option.value} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                {/* <CloseIcon /> */}
                <Xmark
                  style={{
                    height: '18px',
                    width: '18px',
                    fill: 'black',
                    cursor: 'pointer',
                  }}
                />
              </span>
            </div>
          ))}
        </div>
      )
    }
    return selectedValue.label
  }

  return (
    <div className={'dropdown-container ' + errStyle}>
      <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            {/* <Icon /> */}
            <AngleDown
              style={{
                height: '18px',
                width: '18px',
                fill: 'black',
                marginBottom: '-3px',
              }}
            />
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="dropdown-menu">
          {isSearchable && (
            <div className="search-box">
              <input onChange={onSearch} value={searchValue} ref={searchRef} />
            </div>
          )}
          {getOptions().map((option) => (
            <div
              onClick={() => onItemClick(option)}
              key={option.value}
              className={`dropdown-item ${isSelected(option) && 'selected'}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default HomeCatDrop
