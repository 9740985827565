import React, { Fragment } from 'react'
import '../styles/css/sliderCarousel.css'
import Grocery from '../assets/images/grocery.png'

import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const SliderCarousel: React.FC = () => {
  const arr: number[] = [1, 2, 3, 4, 5]
  return (
    <Fragment>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 8500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="maincarouselslider"
      >
        {arr.map((i: number) => {
          return (
            <SwiperSlide key={i}>
              <img
                className="sliderImagePreview"
                src={Grocery}
                loading="lazy"
                alt=""
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Fragment>
  )
}

export default SliderCarousel
