import React, { Fragment } from 'react'
import '../styles/css/categories.css'
import Phones from '../assets/images/phones.png'
import Televisions from '../assets/images/televisions.png'
import Computing from '../assets/images/laptops.png'
import SoundSystems from '../assets/images/sound-systems.png'
import Beauty from '../assets/images/makeup.png'
import Grocery from '../assets/images/grocery.png'
import Clothing from '../assets/images/clothing.png'
import Appliances from '../assets/images/appliances.png'
import OfficeAccesories from '../assets/images/office-accesories.png'
import { useNavigate } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
// import required modules
import { Autoplay, Navigation } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const Categories: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Fragment>
      <div className="categoriesSec">
        <div className="catcont">
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            autoplay={{
              delay: 8500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            breakpoints={{
              640: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
              1000: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              1840: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
            className="catSwiper"
          >
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=phones')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img className="catsliderImagePreview" src={Phones} alt="" />
                </div>
                <p>Phones</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=televisions')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img
                    className="catsliderImagePreview"
                    src={Televisions}
                    alt=""
                  />
                </div>
                <p>Televisions</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=computing')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img
                    className="catsliderImagePreview"
                    src={Computing}
                    alt=""
                  />
                </div>
                <p>computing</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=sound-systems')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img
                    className="catsliderImagePreview"
                    src={SoundSystems}
                    alt=""
                  />
                </div>
                <p>Sound Systems</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=beauty-products')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img className="catsliderImagePreview" src={Beauty} alt="" />
                </div>
                <p>Beauty Products</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=groceries')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img className="catsliderImagePreview" src={Grocery} alt="" />
                </div>
                <p>groceries</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=clothing')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img
                    className="catsliderImagePreview"
                    src={Clothing}
                    alt=""
                  />
                </div>
                <p>Clothings</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=appliances')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img
                    className="catsliderImagePreview"
                    src={Appliances}
                    alt=""
                  />
                </div>
                <p>Appliances</p>
              </div>
            </SwiperSlide>
            <SwiperSlide
              className="catswipeslide"
              onClick={() => {
                navigate('/catalog?category=home-and-office-accessories')
                window.scrollTo(0, 0)
              }}
            >
              <div className="catproperties">
                <div className="catpropImagecont">
                  <img
                    className="catsliderImagePreview"
                    src={OfficeAccesories}
                    alt=""
                  />
                </div>
                <p>Home & Office Accesories</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </Fragment>
  )
}

export default Categories
