import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

interface props {
  title: string
  description: string
  prodimage: string
  revised?: string
}

const BlogMeta = ({
  title,
  description,
  prodimage,
  revised = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date()),
}: props) => {
  title = title.split(' ').slice(0, 35).join(' ')
  description = description.split(' ').slice(0, 150).join(' ')
  return (
    <Fragment>
      <Helmet>
        <title>{title} | nullchemy</title>
        <link rel="canonical" href={window.location.href} />

        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>

        <meta name="keywords" content="nullchemy, Dennis Kibet" />
        <meta name="description" content={description} />
        <meta name="subject" content={title} />
        <meta name="copyright" content="nullchemy" />
        <meta name="language" content="EN" />
        <meta name="robots" content="index, follow" />
        <meta name="revised" content={revised} />
        <meta name="abstract" content="" />
        <meta name="topic" content={title} />
        <meta name="summary" content={description} />
        <meta name="Classification" content="IT, technology, Business" />
        <meta name="author" content="Dennis kibet, dennisrkibet@gmail.com" />
        <meta name="designer" content="Dennis Kibet" />
        <meta name="reply-to" content="dennisrkibet@gmail.com" />
        <meta name="owner" content="Dennis Kibet" />
        <meta name="url" content={window.location.href} />
        <meta name="identifier-URL" content={window.location.href} />
        <meta name="directory" content="submission" />
        <meta name="category" content="IT, Technology, Business" />
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />
        <meta name="rating" content="General" />
        <meta name="revisit-after" content="7 days" />
        <meta http-equiv="Expires" content="0" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Cache-Control" content="no-cache" />

        <meta name="robots" content="index" />
        <link rel="author" href="humans.txt" />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Gro & Medmart" />
        <meta property="og:updated_time" content={revised} />

        <meta property="og:image" content={prodimage} />
        <meta property="og:image:secure_url" content={prodimage} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1800" />
        <meta property="og:image:height" content="550" />

        <link rel="image_src" href={prodimage} />

        <meta name="twitter:card" content={description} />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </Fragment>
  )
}

export default BlogMeta
