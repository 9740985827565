import axios, { AxiosResponse } from 'axios'
import backend from '../utils/backend'
import { toast } from 'react-toastify'

const api = async (
  method: string = 'GET',
  slug: string,
  data: object = {},
  headers: object = {}
): Promise<AxiosResponse> => {
  try {
    const respip: { ip: string } = await axios.get(
      'https://api.ipify.org/?format=json'
    )
    const config = {
      method: method,
      maxBodyLength: Infinity,
      url: backend() + 'api/v1/client/' + slug,
      headers: {
        ...headers,
        ip: respip.ip,
      },
      data: data,
      withCredentials: true,
    }

    const res = await axios(config)
    return res
  } catch (error: any) {
    console.log(error)
    if (error.response.status === 429) {
      toast('Too many Requests! Try again Later', { type: 'error' })
    }
    return { ...error.response, status: error.response?.status || 400 }
  }
}

export default api
