const drpdata = [
  {
    title: 'supermarket',
    to: 'supermarket',
    dropdata: [
      {
        title: 'food cupboard',
        to: 'foods',
        links: [
          {
            title: 'cooking ingredients',
            to: 'cooking-ingredients',
          },
          {
            title: 'snacks, crips and nuts',
            to: 'snacks-crips-and-nuts',
          },
          {
            title: 'grains and nuts',
            to: 'grains-and-nuts',
          },
          {
            title: 'rice and cereals',
            to: 'rice-and-cereals',
          },
          {
            title: 'breakfast',
            to: 'breakfast',
          },
        ],
      },
      {
        title: 'drinks',
        to: '',
        links: [
          {
            title: 'beer, wines and spirits',
            to: 'beer-wines-and-spirits',
          },
          {
            title: 'Carbonated drinks',
            to: 'Carbonated-drinks',
          },
          {
            title: 'Coffee, Tea and Cocoa',
            to: 'Coffee-Tea-and-Cocoa',
          },
          {
            title: 'Dairy',
            to: 'dairy',
          },
          {
            title: 'Juices and non carbonated drinks',
            to: 'Juices-and-non-carbonated-drinks',
          },
        ],
      },
    ],
  },
  {
    title: 'Health & Beauty',
    to: 'health-and-beauty',
    dropdata: [
      {
        title: 'hair care',
        to: '',
        links: [
          {
            title: 'conditioner',
            to: 'conditioner',
          },
          {
            title: 'Extensions, wigs and accessories',
            to: 'Extensions-wigs-and-accessories',
          },
          {
            title: 'Hair and scalp care',
            to: 'Hair-and-scalp-care',
          },
          {
            title: 'hair accessories',
            to: 'hair-accessories',
          },
          {
            title: 'hair coloring products',
            to: 'hair-coloring-products',
          },
        ],
      },
      {
        title: 'oral care',
        to: 'oral-care',
        links: [
          {
            title: "children's dental care",
            to: 'children-dental-care',
          },
          {
            title: 'dental care kits',
            to: 'dental-care-kits',
          },
          {
            title: 'mouthwash',
            to: 'mouthwash',
          },
          {
            title: 'teeth whitenning',
            to: 'teeth-whitenning',
          },
          {
            title: 'toothbrush',
            to: 'toothbrush',
          },
        ],
      },
    ],
  },
  {
    title: 'Clothes & Footware',
    to: 'clothings-and-footware',
    dropdata: [
      {
        title: 'for women',
        to: '',
        links: [
          {
            title: "women's clothing",
            to: 'women-clothing',
          },
          {
            title: "women's shoes",
            to: 'women-shoes',
          },
          {
            title: "women's accessories",
            to: 'women-accessories',
          },
        ],
      },
      {
        title: 'For men',
        to: '',
        links: [
          {
            title: "men's clothing",
            to: 'men-clothing',
          },
          {
            title: "men's shoes",
            to: 'men-shoes',
          },
          {
            title: "men's accessories",
            to: 'men-accessories',
          },
        ],
      },
      {
        title: 'jewellery',
        to: '',
        links: [
          {
            title: "women's jewellery",
            to: 'women-jewellery',
          },
          {
            title: "men's jewellery",
            to: 'men-jewellery',
          },
        ],
      },
    ],
  },
  {
    title: 'Electronics & Video',
    to: 'electronics-and-video',
    dropdata: [
      {
        title: 'Tvs, Monitors & Projectors',
        to: '',
        links: [
          {
            title: 'Televisions',
            to: 'Televisions',
          },
          {
            title: 'computer monitors',
            to: 'computer-monitors',
          },
          {
            title: 'projecors',
            to: 'projecors',
          },
        ],
      },
      {
        title: 'Audio',
        to: '',
        links: [
          {
            title: 'Home Theaters',
            to: 'Home-Theaters',
          },
          {
            title: 'Speakers',
            to: 'Speakers',
          },
          {
            title: 'Subwoofers',
            to: 'Subwoofers',
          },
        ],
      },
      {
        title: 'Cameras',
        to: '',
        links: [
          {
            title: 'DSLR',
            to: 'DSLR',
          },
          {
            title: 'Mirrorless Cameras',
            to: 'Mirrorless-Cameras',
          },
        ],
      },
    ],
  },
  {
    title: 'Computing Accessories',
    to: 'computing-accessories',
    dropdata: [
      {
        title: 'Laptops & Notebooks',
        to: '',
        links: [
          {
            title: 'gaming laptops',
            to: 'gaming-laptops',
          },
          {
            title: 'lightweight laptops',
            to: 'lightweight-laptops',
          },
          {
            title: 'office & coding laptops',
            to: 'office-coding-laptops',
          },
        ],
      },
      {
        title: 'Desktops',
        to: '',
        links: [
          {
            title: '19" monitors',
            to: '19-inches-monitors',
          },
          {
            title: '22" monitors',
            to: '22-inches-monitors',
          },
          {
            title: '24" monitors',
            to: '24-inches-monitors',
          },
          {
            title: 'ultrawide monitors',
            to: 'ultrawide-monitors',
          },
        ],
      },
      {
        title: 'Storage',
        to: '',
        links: [
          {
            title: 'hard disks',
            to: 'hard-disks',
          },
          {
            title: 'ssd',
            to: 'ssd',
          },
        ],
      },
      {
        title: 'Printers & Scanners',
        to: '',
        links: [
          {
            title: 'Impact printers',
            to: 'Impact-printers',
          },
          {
            title: 'Inkjet printers',
            to: 'Inkjet-printers',
          },
          {
            title: '3D printers',
            to: '3d-printers',
          },
        ],
      },
      {
        title: 'Computer Accessories',
        to: '',
        links: [
          {
            title: 'RAM',
            to: 'random-access-memory',
          },
          {
            title: 'GPU',
            to: 'grapgic-cards',
          },
          {
            title: 'CPU',
            to: 'processors',
          },
        ],
      },
    ],
  },
]

export default drpdata
