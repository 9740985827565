import React, { FormEvent, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/auth.css'
import { GoogleLogin } from '@react-oauth/google'
import { Link, useNavigate } from 'react-router-dom'
import Grocery from '../assets/images/grocery.png'
import api from '../api/axios'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../state/hooks'
import { setIsLogged } from '../state/actions/loggedAction'

interface AuthData {
  email: string
  password: string
  cpassword: string
}

const Auth = () => {
  const [isRegistering, setIsRegistering] = useState(false)
  const [data, setData] = useState<AuthData>({
    email: '',
    password: '',
    cpassword: '',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    if (data.email === '' || data.password === '') {
      toast('please check your details!', { type: 'error' })
    } else {
      if (isRegistering) {
        // handle sign up logic
        if (data.cpassword === '') {
          toast('please check your details!', { type: 'error' })
        } else {
          const res = await api('POST', 'auth/register', data)
          setIsLoading(false)
          if (res.status === 200) {
            toast(res.data.message, { type: 'success' })
            setIsRegistering(false)
          } else {
            toast(res.data.message, { type: 'error' })
          }
        }
      } else {
        // handle sign in logic
        const res = await api('POST', 'auth/login', data)
        setIsLoading(false)
        if (res.status === 200) {
          toast(res.data.message, { type: 'success' })
          //set loggedIn state to true
          dispatch(setIsLogged(true))
          setData({
            email: '',
            password: '',
            cpassword: '',
          })
          navigate('/')
        } else {
          console.log(res.data)
          toast(res.data.message, { type: 'error' })
        }
      }
    }
  }
  return (
    <div className="auth">
      <Helmet>
        <title>Authentification | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/auth" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <div className="auth_container">
        <div
          className="auth_wrapper"
          style={isRegistering ? { height: '85vh' } : {}}
        >
          <div className="auth_left">
            <div className="auth_login_box">
              <h1>{isRegistering ? 'Sign Up' : 'Sign In'}</h1>
              <form
                className="auth_login_form"
                onSubmit={(e) => {
                  handleSubmit(e)
                }}
              >
                <div className="auth_form_group">
                  <label htmlFor="Email" className="auth_label">
                    Email <span>*</span>
                  </label>
                  <br />
                  <input
                    type="email"
                    className="auth_login_input"
                    name="email"
                    value={data.email}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value })
                    }}
                  />
                </div>
                <div className="auth_form_group">
                  <label htmlFor="password" className="auth_label">
                    Password <span>*</span>
                  </label>
                  <br />
                  <input
                    type="password"
                    className="auth_login_input"
                    name="password"
                    value={data.password}
                    onChange={(e) => {
                      setData({ ...data, [e.target.name]: e.target.value })
                    }}
                  />
                </div>
                {isRegistering ? (
                  <div className="auth_form_group">
                    <label htmlFor="password" className="auth_label">
                      Confirm Password <span>*</span>
                    </label>
                    <br />
                    <input
                      type="password"
                      className="auth_login_input"
                      name="cpassword"
                      value={data.cpassword}
                      onChange={(e) => {
                        setData({ ...data, [e.target.name]: e.target.value })
                      }}
                    />
                  </div>
                ) : null}
                <button className="auth_btn_submit">
                  {isLoading ? (
                    <div className="dot-flashing"></div>
                  ) : isRegistering ? (
                    'Sign Up'
                  ) : (
                    'Sign In'
                  )}
                </button>
                {isRegistering ? (
                  <p className="auth_info">
                    Already have an account?{' '}
                    <span
                      onClick={() => {
                        setIsRegistering(false)
                      }}
                    >
                      Sign In
                    </span>
                  </p>
                ) : (
                  <p className="auth_info">
                    Don't have an account?{' '}
                    <span
                      onClick={() => {
                        setIsRegistering(true)
                      }}
                    >
                      Sign Up
                    </span>
                  </p>
                )}
                <div className="horizontal_rule_container">
                  <span className="horizontal_rule"></span>
                  <span className="or_text">or</span>
                  <span className="horizontal_rule"></span>
                </div>

                <div className="auth_other_login_options">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      console.log(credentialResponse)
                    }}
                    onError={() => {
                      console.log('Login Failed')
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="auth_right">
            <img className="auth_intro_image" src={Grocery} alt="" />
            <div className="auth_right_wrapper">
              <Link to="/" className="auth_home_logo_link">
                <h2>gro & medmart</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth
