import React, { Fragment, useState, forwardRef, useEffect, useRef } from 'react'
import '../styles/css/searchbar.css'
import { ReactComponent as Lens } from '../assets/svg/lens.svg'
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg'
import { v4 as uuidv4 } from 'uuid'
import OutsideClick from '../utils/outsideClick'
import api from '../api/axios'
import { useNavigate } from 'react-router-dom'

interface Product {
  title: string
  // Add other properties based on your product structure
}

interface SearchBarProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SearchBar = forwardRef<HTMLDivElement, SearchBarProps>(
  ({ open, setOpen }, ref) => {
    const [search, setSearch] = useState('')
    const [resSuggest, setResSuggest] = useState<Product[]>([])
    const [suggestions, setSuggestions] = useState<Product[]>([])
    const inputRef = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()

    useEffect(() => {
      const fetchSuggestions = async () => {
        try {
          const res = await api('GET', 'products/all', {})
          setSuggestions(res.data.products || [])
        } catch (error) {
          console.error('Error fetching suggestions:', error)
        }
      }
      fetchSuggestions()
    }, [])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value
      setSearch(inputValue)

      if (!inputValue) {
        setOpen(false)
        setResSuggest([])
      } else {
        const filteredSuggestions = suggestions.filter((data) =>
          data.title.toLowerCase().includes(inputValue.toLowerCase())
        )
        setOpen(true)
        setResSuggest(filteredSuggestions)
      }
    }

    const handleSuggestionClick = (productName: string) => {
      setSearch(productName)
      setOpen(false)
    }

    // const handleOutsideClick = () => {
    //   if (inputRef.current) {
    //     inputRef.current.blur()
    //     setOpen(false)
    //   }
    // }

    return (
      <Fragment>
        <div className="searchbar">
          <div
            className={open ? 'searchInput activesearchbox' : 'searchInput'}
            ref={ref}
          >
            <input
              type="text"
              value={search}
              onChange={handleInputChange}
              placeholder="Search for products.."
              ref={inputRef}
            />
            {resSuggest.length !== 0 && (
              <div className="SearchresultBox">
                {resSuggest.map((product) => (
                  <li
                    onClick={() => handleSuggestionClick(product.title)}
                    key={uuidv4()}
                  >
                    {product.title}
                  </li>
                ))}
              </div>
            )}
            {search !== '' ? (
              <ArrowRight
                className="searchlens"
                onClick={() => {
                  navigate(`/catalog?search=${search}`)
                }}
              />
            ) : (
              <Lens className="searchlens" />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
)

export default OutsideClick(SearchBar)
