import React, { Fragment, useState } from 'react'
import '../styles/css/priceslider.css'
// import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'

const PriceSlider: React.FC = () => {
  const [price, setPrice] = useState({
    minprice: 0,
    maxprice: 100,
  })
  return (
    <Fragment>
      {/* <RangeSlider
        min={0}
        max={100}
        defaultValue={[0, 100]}
        value={[price.minprice, price.maxprice]}
        id="range-slider-yellow"
        onInput={(value, userInteraction) => {
          setPrice({ minprice: value[0], maxprice: value[1] })
        }}
      /> */}
      <div className="pricerangemanual">
        <div className="minprice">
          <input
            type="number"
            value={price.minprice}
            name="minprice"
            onChange={(e) =>
              setPrice({ ...price, [e.target.name]: e.target.value })
            }
          />
        </div>
        <div className="maxprice">
          <input
            type="number"
            value={price.maxprice}
            name="maxprice"
            onChange={(e) =>
              setPrice({ ...price, [e.target.name]: e.target.value })
            }
          />
        </div>
      </div>
    </Fragment>
  )
}

export default PriceSlider
