import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/cart.css'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as PaymentOnDelivery } from '../assets/svg/payment-delivery.svg'
import { ReactComponent as Visa } from '../assets/svg/visa.svg'
import { ReactComponent as Mpesa } from '../assets/svg/mpesa.svg'
import { ReactComponent as MasterCard } from '../assets/svg/mastercard.svg'
import Header from '../components/Header'
import Footer from '../components/Footerrr'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import FormatCurrency from '../utils/FormatCurrency'
import DeliveryDropdown from '../components/DeliveryDropdown'
import { setCart } from '../state/actions/cart'
import { useNavigate } from 'react-router-dom'
import api from '../api/axios'

const Cart = () => {
  const dispatch = useAppDispatch()
  const cart: any[] | undefined = useAppSelector((state) => state.cart)
  const currency: any = useAppSelector((state) => state.currency)
  const [total, setTotal] = useState<number>(0)
  const navigate = useNavigate()

  useEffect(() => {
    const totalsum: number = Array.isArray(cart)
      ? cart.reduce(
          (total: number, item: any) =>
            total +
            parseFloat(
              Array.isArray(item.price) &&
                item.price.length !== 0 &&
                item.price[0]
            ) *
              item.quantity,
          0
        )
      : 0

    if (totalsum) {
      setTotal(totalsum)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  useEffect(() => {
    const fetchCart = async () => {
      const res = await api('GET', 'cart', { cart: cart })
      console.log(res.data)
      if (res.status === 200) {
      }
    }
    fetchCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  return (
    <Fragment>
      <Helmet>
        <title>Cart | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="cart">
        <div className="cartcontainer">
          <div className="cartwrapper">
            {/* add structure for empty cart case */}
            <div className="mycartcont">
              <div className="mycartlayout">
                <div className="mycartleft">
                  <div className="mycartitle">
                    <h2>{`${Array.isArray(cart) ? cart.length : 0} item${
                      cart && Array.isArray(cart) && cart.length > 1 ? 's' : ''
                    } in your cart`}</h2>
                  </div>
                  {cart &&
                    Array.isArray(cart) &&
                    cart.map((item: any) => {
                      return (
                        <div className="mycartitems" key={item._id}>
                          <div className="cartitempicture">
                            {/* <img src={item.ProdImage} alt="" /> */}
                            <img
                              src={
                                item.arrimages.images[item.arrimages.thumbnail]
                              }
                              loading="lazy"
                              decoding="async"
                              alt=""
                              srcSet=""
                              sizes="(max-width: 640px) 400px, 800px"
                            />
                          </div>
                          <div className="mycartitemdetails">
                            <h2>{item.title}</h2>
                            <p>seller: nullcommerce</p>
                            <p>In Stock: 200</p>
                            <div className="cartitemquantity">
                              <div
                                className="decrement"
                                onClick={() => {
                                  dispatch(
                                    setCart(
                                      cart.map((obj: any) =>
                                        obj._id === item._id
                                          ? {
                                              ...obj,
                                              quantity: Math.max(
                                                item.quantity - 1,
                                                1
                                              ),
                                            }
                                          : obj
                                      )
                                    )
                                  )
                                }}
                              >
                                <span>-</span>
                              </div>
                              <p className="quantitynumber">{item.quantity}</p>
                              <div
                                className="increment"
                                onClick={() => {
                                  dispatch(
                                    setCart(
                                      cart.map((obj: any) =>
                                        obj._id === item._id
                                          ? {
                                              ...obj,
                                              quantity: Math.min(
                                                item.quantity + 1,
                                                10
                                              ),
                                            }
                                          : obj
                                      )
                                    )
                                  )
                                }}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>
                          <div className="mycartitemfarend">
                            <div className="mycartitempricing">
                              <h2>
                                <FormatCurrency
                                  value={
                                    Array.isArray(item.price) &&
                                    item.price.length !== 0
                                      ? parseFloat(item.price[0]) *
                                        parseInt(item.quantity)
                                      : 0
                                  }
                                />
                              </h2>
                              {item.previous_price && (
                                <span className="cartiteminitialprice">
                                  <FormatCurrency value={item.previous_price} />
                                </span>
                              )}
                              {item.previous_price && (
                                <span className="pricingpercentage">{`-${
                                  ((parseFloat(item.previous_price) -
                                    parseFloat(
                                      Array.isArray(item.price) &&
                                        item.price.length !== 0
                                        ? item.price[0]
                                        : 0
                                    )) /
                                    parseFloat(item.previous_price)) *
                                  100
                                }%`}</span>
                              )}
                            </div>
                            <div
                              className="removecartitem"
                              onClick={() => {
                                dispatch(
                                  setCart(
                                    cart.filter(
                                      (obj: any) => obj._id !== item._id
                                    )
                                  )
                                )
                              }}
                            >
                              <Trash className="trashIcon" />
                              <p>remove</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
                <div className="mycartright">
                  <div className="ordersummarytitle">
                    <h2>order summary</h2>
                    <div className="carttotalpricing">
                      {total &&
                      total !== 0 &&
                      Array.isArray(cart) &&
                      cart.length !== 0 ? (
                        <div className="carttotalpricingflex">
                          <h4>total price:</h4>
                          <p>
                            <FormatCurrency value={total} />
                          </p>
                        </div>
                      ) : (
                        total
                      )}

                      <p className="deliveryNotIncluded">
                        Delivery fees not included
                      </p>
                      <h4>
                        Choose Delivery Location{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </h4>
                      <DeliveryDropdown />
                      {/* <DropSelector
                        isSearchable
                        placeHolder="Select..."
                        options={deliverycounties}
                        onChange={(value) => {}}
                        errStyle={''}
                        isMulti={false}
                      /> */}
                    </div>
                    <div className="cartproddelivery">
                      <h3>
                        Delivery Mode <span style={{ color: 'red' }}>*</span>
                      </h3>
                      <fieldset
                        id="categories"
                        className="allprodcatlistfieldset"
                      >
                        <li className="allprodlistitem">
                          <input
                            type="radio"
                            name="categories"
                            className="filterradioselection"
                          />
                          <p>Wells Fargo Courier</p>
                        </li>
                        <li className="allprodlistitem">
                          <input
                            type="radio"
                            name="categories"
                            className="filterradioselection"
                          />
                          <p>Posta Kenya</p>
                        </li>
                      </fieldset>
                      <h3>
                        Choose Payment Method <span>*</span>
                      </h3>
                    </div>
                    <div className="crtchoosepaymentmethod">
                      <div
                        className="crtchooseOptions activepaymentoption"
                        onClick={() => {}}
                      >
                        <PaymentOnDelivery className="PaymentOnDelivery" />
                      </div>
                      <div className="crtchooseOptions" onClick={() => {}}>
                        <Visa className="PaymentOnDelivery visa" />
                      </div>
                      <div className="crtchooseOptions" onClick={() => {}}>
                        <MasterCard className="PaymentOnDelivery mastercardIc" />
                      </div>
                      <div className="crtchooseOptions" onClick={() => {}}>
                        <Mpesa className="PaymentOnDelivery mpesaIc" />
                      </div>
                    </div>
                    <form className="chkmainform" onSubmit={(e) => {}}>
                      <button
                        role="link"
                        id="submit"
                        type="submit"
                        className="btncheckout"
                        onClick={() => {
                          navigate('/checkout')
                        }}
                      >
                        <span>
                          checkout{' '}
                          {total &&
                          total !== 0 &&
                          Array.isArray(cart) &&
                          cart.length !== 0 ? (
                            <FormatCurrency value={total} />
                          ) : (
                            ''
                          )}
                        </span>
                      </button>
                    </form>
                  </div>
                  <div className="cartreturnpolicy"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Cart
