import React, { Fragment, useState } from 'react'
import '../styles/css/mobilesidebar.css'
import { ReactComponent as AngleDown } from '../assets/svg/angle-down.svg'
import { ReactComponent as Close } from '../assets/svg/x-mark.svg'
import { Link } from 'react-router-dom'
import dropdata from '../data/dropdowndata'
import NullchemyLogo from '../assets/images/nullchemy.png'

interface DropdownItem {
  title: string
  to: string
}

interface DropdownData {
  title: string
  dropdata: DropdownItem[]
}

interface MobileSidebarProps {
  ismobiopen: boolean
  setMobiopen: (value: any) => void
}

const MobileSidebar: React.FC<MobileSidebarProps> = ({
  ismobiopen,
  setMobiopen,
}) => {
  const [openDraweLinks, setOpenDrawerLinks] = useState<any[]>([])
  const toggleDrawer = (itemId: string) => {
    setOpenDrawerLinks((prevIds) => {
      if (prevIds.includes(itemId)) {
        return prevIds.filter((id) => id !== itemId)
      } else {
        return [...prevIds, itemId]
      }
    })
  }
  const isDrawerOpen = (itemId: string) => {
    return openDraweLinks.includes(itemId)
  }
  return (
    <Fragment>
      <div className={ismobiopen ? 'mbsidebar showmbsidebar' : 'mbsidebar'}>
        <div className="mbsidebar_container">
          <Close
            className="mbisidebar_close"
            onClick={() => {
              setMobiopen(false)
            }}
          />
          <div className="mobi_header_sidebar">
            <div className="mbi_sdbar_left">
              <img className="mbi_sdbar_logo" src={NullchemyLogo} alt="" />
            </div>
            <div className="mbi_sdbar_right"></div>
          </div>
          <ul className="mbnavlinks">
            {dropdata.map((i: DropdownData) => (
              <li className="mbnavlink" key={i.title}>
                <div className="mbsidecontainer" key={i.title}>
                  <div
                    className="mblinkwrapper"
                    onClick={() => {
                      toggleDrawer(i.title)
                    }}
                  >
                    <p>{i.title}</p>
                    <AngleDown
                      className={
                        isDrawerOpen(i.title)
                          ? 'mbsideangledown mbsideangledown_rotate'
                          : 'mbsideangledown'
                      }
                    />
                  </div>
                  {isDrawerOpen(i.title) && (
                    <div className="mblinkchildren">
                      <ul className="mblinknavchildren">
                        {i.dropdata.map((j: DropdownItem) => (
                          <li className="childmblink" key={j.title}>
                            <Link
                              to={j.to}
                              onClick={() => {
                                setMobiopen(!ismobiopen)
                              }}
                            >
                              <p>{j.title}</p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  )
}

export default MobileSidebar
