import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/homepage.css'
import Header from '../components/Header'
import Landing from '../components/Landing'
import Showcase from '../components/Showcase'
import ShowcaseLoader from '../components/ShowcaseLoader'
import Footer from '../components/Footerrr'
import api from '../api/axios'
import HomeCatDrop from '../components/DropSelector'
import ProdMeta from '../components/ProdMeta'
import { ReactComponent as Server } from '../assets/svg/server.svg'

interface Product {
  _id: number
  ProdImage: string
  Name: string
  Price: number
  // Add other properties as needed
}

interface ApiResponse {
  type: boolean
  data: Product[]
  message?: string
  // Add other properties as needed
}

const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [prods, setProds] = useState<ApiResponse>({ type: false, data: [] })
  const [response, setResponse] = useState<ApiResponse>({
    type: false,
    data: [],
  })

  const fetchProds = async () => {
    try {
      setLoading(true)
      const res: any = await api('GET', 'products/all', {})
      if (res.status === 200) {
        setProds({ type: true, data: res.data.products })
      } else {
        setProds({ type: false, data: [] })
      }
    } catch (error) {
      setProds({ type: false, data: [] })
      console.error('Error fetching products:', error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchProds()
  }, [])

  const homeAddToCart = async (id: number) => {
    try {
      let authToken = sessionStorage.getItem('authToken')
      const res: any = await api('POST', 'cart/add', {
        authToken: authToken,
        _id: id,
        Quantity: 1,
      })
      setResponse(res.data)
      setTimeout(() => {
        setResponse({ type: false, data: [] })
      }, 2000)
    } catch (error) {
      console.error('Error adding to cart:', error)
    }
  }

  const categories = [
    { value: 'nairobi', label: 'Phones' },
    { value: 'nakuru', label: 'Televisions' },
    // Add other categories
  ]

  const brands = [
    { value: 'nairobi', label: 'Nairobi' },
    { value: 'nakuru', label: 'Nakuru' },
    // Add other brands
  ]

  return (
    <Fragment>
      <Helmet>
        <title>Home | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <ProdMeta
        title="Home"
        description="do your shopping the easy way"
        prodimage=""
      />
      <Header />
      <Landing />
      <div className="showcase">
        <div className="showcasewrapper">
          {/* Category and Brand dropdowns */}
          <div className="shcasebreadcrumb">
            <div className="shcatbrd">
              <HomeCatDrop
                isSearchable
                isMulti
                placeHolder="categories..."
                options={categories}
                onChange={(value) => console.log(value)}
              />
            </div>
            <div className="shbrandsbrd">
              <HomeCatDrop
                isSearchable
                isMulti
                placeHolder="Select..."
                options={brands}
                onChange={(value) => console.log(value)}
              />
            </div>
          </div>
          {loading ? (
            <ShowcaseLoader />
          ) : prods.type ? (
            <div className="showcases">
              <Showcase
                prods={prods}
                response={response}
                homeAddToCart={homeAddToCart}
                all={false}
                category="Grocery"
                navigation={true}
              />
              <Showcase
                prods={prods}
                response={response}
                homeAddToCart={homeAddToCart}
                all={false}
                category="Electronics"
                navigation={true}
              />
              <Showcase
                prods={prods}
                response={response}
                homeAddToCart={homeAddToCart}
                all={false}
                category="Clothings"
                navigation={true}
              />
            </div>
          ) : (
            <div className="showcase_prods_fetch_err">
              <div className="showcase_err_top_fetch">
                <div className="showcaer_top_err_left">
                  <Server className="showcase_server_ic" />
                  <h1 className="showcase_server_status">500</h1>
                </div>
                <div className="showcaer_top_err_right">
                  <button
                    onClick={() => {
                      fetchProds()
                    }}
                    className="showcase_retry"
                  >
                    retry
                  </button>
                </div>
              </div>
              <h1 className="showcase_err_message">Error Fetching Products</h1>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Home
