import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/checkout.css'
import Header from '../components/Header'
import Footer from '../components/Footerrr'
import { ReactComponent as Plus } from '../assets/svg/plus.svg'
import { ReactComponent as Minus } from '../assets/svg/minus.svg'
import { ReactComponent as Print } from '../assets/svg/print.svg'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import FormatCurrency from '../utils/FormatCurrency'
import DeliveryDropdown from '../components/DeliveryDropdown'
import { ReactComponent as PaymentOnDelivery } from '../assets/svg/payment-delivery.svg'
import { ReactComponent as Visa } from '../assets/svg/visa.svg'
import { ReactComponent as Mpesa } from '../assets/svg/mpesa.svg'
import { ReactComponent as MasterCard } from '../assets/svg/mastercard.svg'
import { ReactComponent as Close } from '../assets/svg/x-mark.svg'
import api from '../api/axios'
import { validateAndCleanPhoneNumber } from '../utils/validatephone'
import { socket } from '../socket'
import { setCart } from '../state/actions/cart'

const Checkout: React.FC = () => {
  const cart: {}[] | undefined = useAppSelector((state) => state.cart)
  const [openDrawerIds, setOpenDrawerIds] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [phone, setPhone] = useState<string>('')
  const [activepayment, setActivePayment] = useState<string>('mpesa')
  const [paymentloading, setPaymentLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [err, setErr] = useState<{
    type: string
    message: string
    active: boolean
  }>({
    type: '',
    message: '',
    active: false,
  })
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setCart(cart && Array.isArray(cart) ? cart : []))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleDrawer = (itemId: number) => {
    setOpenDrawerIds((prevIds) => {
      if (prevIds.includes(itemId)) {
        return prevIds.filter((id) => id !== itemId)
      } else {
        return [...prevIds, itemId]
      }
    })
  }

  const isDrawerOpen = (itemId: number) => {
    return openDrawerIds.includes(itemId)
  }
  useEffect(() => {
    const total: number | undefined =
      Array.isArray(cart) && cart.length > 0
        ? cart.reduce(
            (total: number, item: any) => total + (item.price[0] || 0),
            0
          )
        : 0

    if (total) {
      setTotal(total)
    }
  }, [cart])
  const mpesa = async () => {
    const cleanphone = validateAndCleanPhoneNumber(phone)
    if (cleanphone.isvalid) {
      setErr({ type: '', message: '', active: false })
      setPaymentLoading(true)
      const res = await api('POST', 'mpesa-pay', {
        cart,
        phone: cleanphone.cleanedNumber,
      })
      setPaymentLoading(false)
      if (res.status === 200) {
        setProcessing(true)
      }
      console.log(res)
    } else {
      setErr({
        type: 'payment',
        message: `invalid phone number: ${cleanphone.message}`,
        active: true,
      })
    }
  }
  useEffect(() => {
    socket.on('payment_complete', (data: any) => {
      if (data.phone) setProcessing(false)
      //toast(message)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  console.log(cart)
  return (
    <Fragment>
      <Helmet>
        <title>Checkout | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="checkout">
        <div className="checkout_container">
          {processing && (
            <div
              className="payment_processing_md_p"
              onClick={() => {
                setProcessing(false)
              }}
            >
              <div
                className="payment_processing_md_p_wrap"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Close
                  className="close_payment_processing"
                  onClick={() => {
                    setProcessing(false)
                  }}
                />
                <div className="pr_center_md_p">
                  <p>Complete the Payment on your phone</p>
                  <br />
                  <div className="spinner pr_center_md_p_spinner"></div>
                </div>
              </div>
            </div>
          )}
          <div className="checkout_wrapper">
            <div className="checkout_flexxer">
              {Array.isArray(cart) && cart.length !== 0 ? (
                <div className="checkout_cart_summary">
                  <h1>Cart Summary</h1>
                  <Print
                    className="print_cart_summary_checkout"
                    title="print cart summary"
                  />
                  <div className="checkout_cart_summary_wrapper">
                    <ul className="checkout_cart_summary_unordered">
                      {Array.isArray(cart) &&
                        cart.map((i: any) => {
                          return (
                            <li
                              className="checkout_cart_summary_item"
                              key={i._id}
                            >
                              <div className="checkout_cart_summary_item_wrapper">
                                {isDrawerOpen(i._id) ? (
                                  <Minus
                                    className="checkout_cart_summary_ic"
                                    onClick={() => toggleDrawer(i._id)}
                                  />
                                ) : (
                                  <Plus
                                    className="checkout_cart_summary_ic"
                                    onClick={() => toggleDrawer(i._id)}
                                  />
                                )}

                                <div className="checkout_cart_summary_info">
                                  <div className="checkout_cart_summary_inner_wrapper">
                                    <p className="summarized_product_name">
                                      {i.title}
                                    </p>
                                    <p>
                                      Qty: 2 &nbsp;&nbsp;
                                      <FormatCurrency value={i.price[0]} />
                                    </p>
                                  </div>
                                  {isDrawerOpen(i._id) && (
                                    <div className="checkout_summary_compact_details">
                                      <p>Discount 20%</p>
                                      <p>
                                        Tax: <FormatCurrency value={2500} />
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                    <div className="checkout_summary_total">
                      <div className="checkout_summary_total_left"></div>
                      <div className="checkout_summary_total_right">
                        <h3>
                          <span>Delivery Fees:</span>
                          <span>
                            <FormatCurrency value={2500} />
                          </span>
                        </h3>
                        <h3>
                          <span>Tax:</span>
                          <span>
                            <FormatCurrency value={300} />
                          </span>
                        </h3>
                        <h1>
                          <span>TOTAL:</span>
                          <span>
                            <FormatCurrency value={total} />
                          </span>
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="checkout_cart_summary"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '60vh',
                  }}
                >
                  <div className="infobox" style={{ textAlign: 'center' }}>
                    <p
                      style={{
                        backgroundColor: '#FFBF00',
                        padding: '10px 20px',
                        fontSize: '1.6rem',
                      }}
                    >
                      Your cart is currently empty. <br /> Please shop for
                      something to be able to checkout.
                    </p>
                  </div>
                </div>
              )}
              {Array.isArray(cart) && cart.length !== 0 ? (
                <div className="checkout_right">
                  <div className="checkout_delivery_information">
                    <h1>Delivery Information</h1>
                    <div className="chk_deliv_info_wrapper">
                      <DeliveryDropdown />
                    </div>
                  </div>
                  <div className="checkout_pay_now">
                    <h1>Payment Options</h1>
                    {err.active ? (
                      <div className="payment_error">
                        <p>{err.message}</p>
                      </div>
                    ) : null}
                    <div className="ck_pay_methods">
                      <div className="crtchoosepaymentmethod">
                        <div
                          className={
                            activepayment === 'mpesa'
                              ? 'crtchooseOptions activepaymentoption'
                              : 'crtchooseOptions'
                          }
                          onClick={() => {
                            setActivePayment('mpesa')
                          }}
                        >
                          <Mpesa className="PaymentOnDelivery mpesaIc" />
                        </div>
                        <div
                          className={
                            activepayment === 'cash'
                              ? 'crtchooseOptions activepaymentoption'
                              : 'crtchooseOptions'
                          }
                          onClick={() => {
                            setActivePayment('cash')
                          }}
                        >
                          <PaymentOnDelivery className="PaymentOnDelivery" />
                        </div>
                        <div
                          className={
                            activepayment === 'visa'
                              ? 'crtchooseOptions activepaymentoption'
                              : 'crtchooseOptions'
                          }
                          onClick={() => {
                            setActivePayment('visa')
                          }}
                        >
                          <Visa className="PaymentOnDelivery visa" />
                        </div>
                        <div
                          className={
                            activepayment === 'mastercard'
                              ? 'crtchooseOptions activepaymentoption'
                              : 'crtchooseOptions'
                          }
                          onClick={() => {
                            setActivePayment('mastercard')
                          }}
                        >
                          <MasterCard className="PaymentOnDelivery mastercardIc" />
                        </div>
                      </div>
                    </div>
                    <div className="ck_pay_methods_playarea">
                      {activepayment === 'mpesa' ? (
                        <div className="chk_mpesa_pay_option">
                          <p>
                            Enter your mpesa registered number and you will get
                            a pop up on your phone to complete the payment
                          </p>
                          <div className="chk_mpesa_pay_option_flexer">
                            <input
                              className="mpesa_input_number_chk"
                              type="text"
                              value={phone}
                              placeholder="Enter mpesa number..."
                              onChange={(e) => {
                                setPhone(e.target.value)
                              }}
                            />
                            <button
                              className="mpesa_pay_btn"
                              onClick={() => {
                                mpesa()
                              }}
                            >
                              {paymentloading ? (
                                <div className="dot-flashing"></div>
                              ) : (
                                'pay'
                              )}
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {activepayment === 'cash' ? (
                        <div className="chk_cash_pay_option">
                          <p>
                            You will pay on Delivery, Bring your national ID or
                            passport on pick up
                          </p>
                          <button
                            className="cash_complete_btn"
                            onClick={() => {
                              mpesa()
                            }}
                          >
                            {paymentloading ? (
                              <div className="dot-flashing"></div>
                            ) : (
                              'complete'
                            )}
                          </button>
                        </div>
                      ) : null}
                      {activepayment === 'visa' ? (
                        <div className="chk_visa_pay_option"></div>
                      ) : null}
                      {activepayment === 'mastercard' ? (
                        <div className="chk_mastercard_pay_option"></div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Checkout
