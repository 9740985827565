import React, { Fragment } from 'react'
import {
  createRoutesFromElements,
  Route,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import Home from './routes/HomeT'
import Auth from './routes/Auth'
import Cart from './routes/Cart'
import NotFound from './routes/NotFound'
import AllProducts from './routes/AllProducts'
import SellWithUs from './routes/SellWithUs'
import HelpCenter from './routes/HelpCenter'
import ContactUs from './routes/ContactUs'
import ShippingAndDelivery from './routes/ShippingAndDelivery'
import ReturnPolicy from './routes/ReturnPolicy'
import DisputeResCenter from './routes/DisputeResCenter'
import Report from './routes/Report'
import PaymentMethods from './routes/PaymentMethods'
import Checkout from './routes/Checkout'
import Success from './routes/Success'
import Canceled from './routes/Canceled'
import ProductDetails from './routes/ProductDetails'
import Shop from './routes/Shop'
import Profile from './routes/Profile'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route path="/" element={<Home />}></Route>
      <Route path="/auth" element={<Auth />}></Route>
      <Route path="/sell" element={<SellWithUs />}></Route>
      <Route path="/shop" element={<Shop />}></Route>
      <Route path="/catalog" element={<AllProducts />}></Route>
      <Route path="/cart" element={<Cart />}></Route>
      <Route path="/help-center" element={<HelpCenter />}></Route>
      <Route path="/contact-us" element={<ContactUs />}></Route>
      <Route
        path="/shipping-and-delivery"
        element={<ShippingAndDelivery />}
      ></Route>
      <Route path="/return-policy" element={<ReturnPolicy />}></Route>
      <Route
        path="/dispute-resoution-center"
        element={<DisputeResCenter />}
      ></Route>
      <Route path="/report-product" element={<Report />}></Route>
      <Route path="/payment" element={<PaymentMethods />}></Route>
      <Route path="/about-us" element={<PaymentMethods />}></Route>
      <Route path="/blog" element={<PaymentMethods />}></Route>
      <Route path="/careers" element={<PaymentMethods />}></Route>
      <Route path="/terms-and-conditions" element={<PaymentMethods />}></Route>
      <Route path="/privacy-policy" element={<PaymentMethods />}></Route>
      <Route path="/cookie-policy" element={<PaymentMethods />}></Route>
      <Route path="/advertise-with-us" element={<PaymentMethods />}></Route>
      <Route path="/feedback" element={<PaymentMethods />}></Route>
      <Route path="/gift-cards" element={<PaymentMethods />}></Route>
      <Route
        path="/vouchers-and-promotions"
        element={<PaymentMethods />}
      ></Route>
      <Route path="/my-points" element={<PaymentMethods />}></Route>
      <Route path="/currency-converter" element={<PaymentMethods />}></Route>
      <Route path="/checkout" element={<Checkout />}></Route>
      <Route path="/success" element={<Success />}></Route>
      <Route path="/canceled" element={<Canceled />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      <Route path="/product/:ProductSlug" element={<ProductDetails />}></Route>
      <Route path="*" element={<NotFound />}></Route>
    </Fragment>
  )
)
const RoutesPg = () => {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  )
}

export default RoutesPg
