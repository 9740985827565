import React, { Fragment, ReactElement, useEffect } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import RoutesPg from './Routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAppDispatch } from './state/hooks'
import { setCurrency } from './state/actions/currency'
import { setCountry } from './state/actions/country'
import { socket } from './socket'
import { setSocket } from './state/actions/socket'
import { navigatorGetUserLocation } from './utils/location'
import { setExchange } from './state/actions/exchange'
import api from './api/axios'

const App = (): ReactElement => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const fetchExchange = async () => {
      console.log('Fetching Exchangev from App')
      const res = await api('GET', 'exchange/all')
      if (res.status && res?.status === 200) {
        dispatch(setExchange(res.data))
      }
    }
    fetchExchange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    socket.connect()
    // Cleanup function
    return () => {
      socket.disconnect()
    }
  }, [])
  useEffect(() => {
    socket.on('socket_id', (id: string) => {
      dispatch(setSocket(id))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  const getUserLocationAndSetCookies = async () => {
    console.log('Finding Location')
    try {
      const userdata: { country: any[]; currency: any[] } =
        await navigatorGetUserLocation()

      console.log(userdata)

      if (userdata.country.length !== 0) {
        dispatch(setCountry(userdata.country[0]))
      }
      if (userdata.currency.length !== 0) {
        dispatch(setCurrency(userdata.currency[0]))
      }
      if (userdata.currency.length !== 0 && userdata.country.length !== 0) {
        Cookies.set(
          '__UDNCC',
          JSON.stringify({
            userdata: {
              country: userdata.country[0],
              currency: userdata.currency[0],
            },
          })
        )
      }
    } catch (error) {
      console.error('Error getting user location:', error)
      // Handle error here
    }
  }

  useEffect(() => {
    getUserLocationAndSetCookies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <div className="appcontents">
        <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText="Okay"
          cookieName="shop_nullchemy"
          buttonClasses="button"
          containerClasses="cookieConsent"
          expires={150}
          onOverlayClick={() => {
            getUserLocationAndSetCookies()
          }}
        >
          <p className="cookieText">
            We use cookies to improve your shopping experience.
          </p>
        </CookieConsent>
        <ToastContainer hideProgressBar />
        <RoutesPg />
      </div>
    </Fragment>
  )
}

export default App
