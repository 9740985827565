import React from 'react'
import '../styles/css/deliverydropdown.css'
import DropSelector from './DropSelector'

const DeliveryDropdown = () => {
  const deliverycounties = [
    { value: 'nairobi', label: 'Nairobi' },
    { value: 'nakuru', label: 'Nakuru' },
    { value: 'eldoret', label: 'Eldoret' },
    { value: 'thika', label: 'Thika' },
    { value: 'machakos', label: 'Machakos' },
    { value: 'mombasa', label: 'Mombasa' },
    { value: 'kisumu', label: 'Kisumu' },
    { value: 'kericho', label: 'Kericho' },
  ]
  return (
    <div className="deliverydropdown">
      <div className="deliverydropdown_container">
        <div className="deliverydropdown_wrapper">
          <div className="deliverydropdown_selected_locations">
            <ul className="del_sel_loc_list">
              <li className="selected_loc_item">
                <p>Kenya</p>
              </li>
              <li className="selected_loc_item">
                <p>Kericho</p>
              </li>
              <li className="selected_loc_item">
                <p>Ng'oina road</p>
              </li>
              <li className="selected_loc_item">
                <p>Roret</p>
              </li>
            </ul>
          </div>
          <div className="deliverydropdown_selector">
            <DropSelector
              isSearchable
              placeHolder="Select..."
              options={deliverycounties}
              onChange={(value) => {}}
              errStyle={''}
              isMulti={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeliveryDropdown
