import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/success.css'
import { ReactComponent as OrderCompleted } from '../assets/svg/undraw/order_confirmed.svg'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footerrr'

const Success: React.FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Success | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="paymentsuccess">
        <div className="psucccontainer">
          <div className="psucclayout">
            <div className="psuccleft">
              <div className="psuccinfo">
                <div className="psucctptexts">
                  <h1>Payment Successful</h1>
                  <p>
                    your payment was received successfully. Your products are on
                    their way. Thank you for choosing Gro & MedMart
                  </p>
                </div>
                <div className="psuccprodprogress">
                  <span>
                    <p>products selected</p>
                  </span>
                  <span>
                    <p>payment received</p>
                  </span>
                  <span>
                    <p>shipped</p>
                  </span>
                  <span>
                    <p>picked</p>
                  </span>
                </div>
                <Link to="/" className="psuccbackhome psuccbackgreen">
                  home
                </Link>
              </div>
            </div>
            <div className="psuccright">
              <div className="psuccimgwrapper">
                <OrderCompleted />
                {/* <img src={OrderCompleted} alt="" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Success
