import React, { Fragment, useEffect, useState, forwardRef } from 'react'
import '../styles/css/header.css'
import DropDown from './DropDown'
import MobileSidebar from './MobileSidebar'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Logout } from '../assets/svg/logout.svg'
import { ReactComponent as Cart } from '../assets/svg/shopping-cart.svg'
import { ReactComponent as Bars } from '../assets/svg/bars.svg'
import { ReactComponent as AngleDown } from '../assets/svg/angle-down.svg'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import OutsideClick from '../utils/outsideClick'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import MetaSelector from './MetaSelector'
import NullchemyLogo from '../assets/images/nullchemy.png'
import { setIsLogged } from '../state/actions/loggedAction'

interface HeaderProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ open, setOpen }, ref) => {
    const [ismobiopen, setMobiopen] = useState<boolean>(false)
    const cart: {}[] | undefined = useAppSelector((state) => state.cart)
    const isuserloggedin = useAppSelector((state) => state.logged)
    const dispatch = useAppDispatch()
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    )

    useEffect(() => {
      if (vw > 480) {
        setMobiopen(false)
      }
    }, [vw])

    useEffect(() => {
      const handleScroll = () => {
        const header = document.querySelector('.header')
        const scrollTop = window.scrollY
        if (header) {
          scrollTop >= 70
            ? header.classList.add('is-sticky')
            : header.classList.remove('is-sticky')
        }
      }

      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])

    const logout = (): void => {
      document.cookie =
        'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      dispatch(setIsLogged(false))
    }

    useEffect(() => {
      console.log(ismobiopen)
    }, [ismobiopen])

    return (
      <Fragment>
        <div className="header" id="header">
          <div className="hcontainer">
            <div className="left">
              <div className="logoname">
                <Bars
                  className="mobibars"
                  onClick={() => {
                    setMobiopen(true)
                  }}
                />
                <Link to="/" className="logonamelink">
                  <h2>gro & medmart</h2>
                  <img
                    className="nullchemy_logo_mobile"
                    src={NullchemyLogo}
                    alt=""
                  />
                </Link>
              </div>
              <div className="filtercategories">
                <DropDown />
              </div>
              <div className="mobilesidebarnav">
                <MobileSidebar
                  ismobiopen={ismobiopen}
                  setMobiopen={setMobiopen}
                />
              </div>
            </div>
            <div className="middle">
              <SearchBar />
            </div>
            <div className="right">
              <Link to="/sell">
                <span>sell with us</span>
              </Link>
              <div className="header_currency_language">
                <MetaSelector />
              </div>
              <div className="login">
                {isuserloggedin ? (
                  <div className="accountdropdown" ref={ref}>
                    <div
                      className="linktologinusr"
                      onClick={() => setOpen(!open)}
                    >
                      <User className="headeruser usr_drop_ic" />
                      <span>Account</span>
                      <AngleDown className="angleusrdownaccount" />
                    </div>
                    <div className={open ? 'acctdropdown' : 'hide'}>
                      <Link
                        className="linktologin"
                        to="/profile"
                        onClick={() => setOpen(!open)}
                      >
                        <User className="headeruser usr_drop_ic" />
                        <p>profile</p>
                      </Link>
                      <div
                        className="linktologin"
                        onClick={() => {
                          logout()
                          setOpen(false)
                        }}
                      >
                        <Logout className="headeruser usr_drop_ic" />
                        <p>logout</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link className="linktologin" to="/auth">
                    <User className="headeruser" />
                  </Link>
                )}
              </div>
              <div className="headercart">
                <div className="minicartdropdown">
                  <div className="linktocartusr">
                    <Link className="linktocart" to="/cart">
                      <Cart className="headcart" />
                      <span className="cartQuantityCounter">
                        {/* {isuserloggedin ? cartquantity : 0} */}
                        {Array.isArray(cart) ? cart.length : 0}
                      </span>
                      <span>cart</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
)

export default OutsideClick(Header)
