import React, { useEffect, useState } from 'react'
import '../styles/css/metaselector.css'
import { ReactComponent as AngleDown } from '../assets/svg/angle-down.svg'
import countries from '../data/countries.json'
import currencies from '../data/common_currency.json'
import { setCountry } from '../state/actions/country'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { setCurrency } from '../state/actions/currency'
import Fuse from 'fuse.js'

const MetaSelector = () => {
  const [activeCountry, setActiveCountry] = useState<any>(countries[0])
  const [open, setOpen] = useState<boolean>(false)
  const [currfilter, setCurrFilter] = useState<string>('')
  const [currencylist, setCurencyList] = useState<{}[]>(currencies)
  const [countryfilter, setCountryFilter] = useState<string>('')
  const [countrylist, setCountryList] = useState<{}[]>(countries)

  const dispatch = useAppDispatch()
  const country: any = useAppSelector((state) => state.country)
  const currency: any = useAppSelector((state) => state.currency)
  const { protocol, hostname, port } = window.location
  const domainWithPort = port ? `${hostname}:${port}` : hostname
  useEffect(() => {
    if (country && !(Object.keys(country).length === 0)) {
      // country is not empty then use set country
      setActiveCountry(country)
    }
  }, [country])
  const filterCurrencies = (arr: {}[], searchTerm: string): {}[] => {
    if (!searchTerm.trim()) {
      return arr
    }
    const options = {
      keys: ['symbol', 'code', 'name'],
      threshold: 0.3,
    }
    const fuse = new Fuse(arr, options)
    const results = fuse.search(searchTerm as string)
    return results.map((item: any) => item.item)
  }
  useEffect(() => {
    if (!currfilter.trim()) {
      setCurencyList(currencies)
    } else {
      setCurencyList(filterCurrencies(currencies, currfilter))
    }
  }, [currfilter])

  const filterCountries = (arr: {}[], searchTerm: string): {}[] => {
    if (!searchTerm.trim()) {
      return arr
    }
    const options = {
      keys: ['iso_3166_3', 'name'],
      threshold: 0.3,
    }
    const fuse = new Fuse(arr, options)
    const results = fuse.search(searchTerm as string)
    return results.map((item: any) => item.item)
  }

  useEffect(() => {
    if (!countryfilter.trim()) {
      setCountryList(countries)
    } else {
      setCountryList(filterCountries(countries, countryfilter))
    }
  }, [countryfilter])
  return (
    <div
      className="meta_selector_parent"
      onClick={() => {
        setOpen(!open)
      }}
    >
      <div
        className="meta_selector"
        title={
          country && currency ? `${country?.name} - ${currency?.name}` : ''
        }
      >
        <div className="meta_selector_container">
          <div
            className="meta_selector_wrapper"
            onClick={() => {
              setOpen(!open)
            }}
          >
            <div className="meta_selector_inner_wrapper">
              <div className="meta_selector_flag">
                <img
                  src={`${protocol}//${domainWithPort}/countries/${activeCountry.flag}`}
                  alt=""
                />
              </div>
              <span>{activeCountry.iso_3166_3}</span>
            </div>
            <div className="meta_selector_right">
              <div className="meta_selector_iso_name">
                <span>{currency?.symbol}</span>
              </div>
              <AngleDown className="meta_selector_angle_down" />
            </div>
          </div>
          {open ? (
            <div
              className="meta_selector_drop_down_parent"
              onClick={() => {
                setOpen(false)
              }}
            >
              <div
                className="meta_selector_drop_down"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <ul className="meta_selector_ordered_list_countries">
                  <input
                    type="text"
                    className="meta_selector_filter_options"
                    placeholder="select a country..."
                    value={countryfilter}
                    onChange={(e) => {
                      setCountryFilter(e.target.value)
                    }}
                  />
                  {countrylist &&
                    countrylist.map((i: any) => {
                      return (
                        <li
                          className="meta_selector_ordered_list_items"
                          onClick={() => {
                            dispatch(setCountry(i))
                          }}
                          style={
                            country?.name === i.name
                              ? { backgroundColor: '#c3c3c3' }
                              : {}
                          }
                          key={i.name}
                        >
                          <div
                            className="meta_selector_flg"
                            style={{ height: '20px', width: '20px' }}
                          >
                            <img
                              src={`${protocol}//${domainWithPort}/countries/${i.flag}`}
                              alt=""
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                          <span className="meta_selector_country_name">{`${i.name} (${i.iso_3166_3})`}</span>
                        </li>
                      )
                    })}
                </ul>
                <ul className="meta_selector_ordered_list_currency">
                  <input
                    type="text"
                    className="meta_selector_filter_options"
                    placeholder="select a currency..."
                    value={currfilter}
                    onChange={(e) => {
                      setCurrFilter(e.target.value)
                    }}
                  />
                  {currencylist &&
                    currencylist.map((i: any) => {
                      return (
                        <li
                          className="meta_selector_ordered_list_items"
                          onClick={() => {
                            dispatch(setCurrency(i))
                          }}
                          style={
                            currency?.name === i.name
                              ? { backgroundColor: '#c3c3c3' }
                              : {}
                          }
                          key={i.name}
                        >
                          <div className="meta_selector_symbol">
                            <span>{i.symbol}</span>
                          </div>
                          <span style={{ color: 'black' }}>-</span>
                          <span className="meta_selector_currency_name">
                            {i.name}
                          </span>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default MetaSelector
