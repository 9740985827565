type Action = {
  type: string
  payload?: {}
}
const country = (state: {} = {}, action: Action) => {
  switch (action.type) {
    case 'SETCOUNTRY':
      return action.payload
    default:
      return state
  }
}

export default country
