import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import '../styles/css/profile.css'
import { ReactComponent as StrokeHeart } from '../assets/svg/heart.svg'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Box } from '../assets/svg/box.svg'
import { ReactComponent as Pen } from '../assets/svg/pen.svg'
import { ReactComponent as Receipts } from '../assets/svg/receipt.svg'
import { ReactComponent as Message } from '../assets/svg/message.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as GiftCard } from '../assets/svg/gift.svg'
import { ReactComponent as ClockRotate } from '../assets/svg/clock-rotate.svg'
import Header from '../components/Header'
import Footer from '../components/Footerrr'

const Profile: React.FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Profile | nullchemy</title>
        <link rel="canonical" href="http://shop.nullchemy.com/about" />
        <script>
          window.dataLayer = window.dataLayer || []; function
          gtag()&#123;dataLayer.push(arguments);&#125; gtag('js', new Date());
          gtag('config', 'G-QTPSF2RRB6');
        </script>
      </Helmet>
      <Header />
      <div className="profile">
        <div className="profileContainer">
          <div className="profileWrapper">
            <div className="profNav">
              <ul className="profunlist">
                <li className="profNavLink profNavLinkActive">
                  <User className="profIcNav" />
                  <p className="profNavLinkCont">My Account</p>
                </li>
                <li className="profNavLink">
                  <Box className="profIcNav" />
                  <p className="profNavLinkCont">Orders</p>
                </li>
                <li className="profNavLink">
                  <Message className="profIcNav" />
                  <p className="profNavLinkCont">Inbox</p>
                </li>
                <li className="profNavLink">
                  <Receipts className="profIcNav" />
                  <p className="profNavLinkCont">Receipts</p>
                </li>
                <li className="profNavLink">
                  <Pen className="profIcNav" />
                  <p className="profNavLinkCont">Pending Reviews</p>
                </li>
                <li className="profNavLink">
                  <Receipts className="profIcNav" />
                  <p className="profNavLinkCont">Vouchers</p>
                </li>
                <li className="profNavLink">
                  <GiftCard className="profIcNav" />
                  <p className="profNavLinkCont">Gift Cards</p>
                </li>
                <li className="profNavLink">
                  <StrokeHeart className="profIcNav" />
                  <p className="profNavLinkCont">Saved Items</p>
                </li>
                <li className="profNavLink">
                  <ClockRotate className="profIcNav" />
                  <p className="profNavLinkCont">Recently Viewed</p>
                </li>
                <li className="profNavLink">
                  <Trash className="profIcNav" />
                  <p className="profNavLinkCont">Close Account</p>
                </li>
              </ul>
            </div>
            <div className="profPlayarea">
              <div className="accoverview">
                <div className="accrevtop">
                  <h1>Account Overview</h1>
                </div>
                <div className="accrevCont">
                  <div className="accovdetcont">
                    <div className="acovtopdet">
                      <h2>Account details</h2>
                      <Pen className="profaccovIcNav" />
                    </div>
                    <div className="accovdetcontdet"></div>
                  </div>
                  <div className="accovdetcont">
                    <div className="acovtopdet">
                      <h2>Shipping Address</h2>
                      <Pen className="profaccovIcNav" />
                    </div>
                    <div className="accovdetcontdet"></div>
                  </div>
                  <div className="accovdetcont">
                    <div className="acovtopdet">
                      <h2>Account details</h2>
                      <Pen className="profaccovIcNav" />
                    </div>
                    <div className="accovdetcontdet"></div>
                  </div>
                  <div className="accovdetcont">
                    <div className="acovtopdet">
                      <h2>Account details</h2>
                      <Pen className="profaccovIcNav" />
                    </div>
                    <div className="accovdetcontdet"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

export default Profile
