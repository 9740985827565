type Action = {
  type: string
  payload?: {}[]
}
const cart = (state: {}[] = [], action: Action) => {
  switch (action.type) {
    case 'SETCART':
      return action.payload
    default:
      return state
  }
}

export default cart
