import { combineReducers } from 'redux'
import loggedReducer from './loggedReducer'
import cart from './cart'
import currency from './currency'
import country from './country'
import socket from './socket'
import exchange from './exchange'

const allReducers = combineReducers({
  logged: loggedReducer,
  cart: cart,
  currency: currency,
  country: country,
  socket: socket,
  exchange: exchange,
})

export default allReducers
