import React from 'react'
import '../styles/css/showcaseloader.css'
// import 'react-loading-skeleton/dist/skeleton.css'

const ShowcaseLoader = () => {
  return (
    <div className="showcaseLoader">
      {Array.from(Array(10).keys()).map((i: any) => {
        return (
          <div className="showcasecontloading" key={i}>
            <div className="c-skeleton-square" />
            <div className="c-item__bottom">
              <div className="c-skeleton-circle" />
              <div className="c-skeleton-line" />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ShowcaseLoader
