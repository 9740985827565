import React, { Fragment, useEffect, useRef, useState } from 'react'
import ProdMeta from '../components/ProdMeta'
import '../styles/css/productdetails.css'
import Header from '../components/Header'
import Footer from '../components/Footerrr'
import { ReactComponent as SolidHeart } from '../assets/svg/solid-heart.svg'
import { ReactComponent as StrokeHeart } from '../assets/svg/heart.svg'
import { ReactComponent as SolidBookmark } from '../assets/svg/bookmark-solid.svg'
import { ReactComponent as BookMark } from '../assets/svg/bookmark.svg'
import api from '../api/axios'
import { useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Ratings from '../components/Ratings'
import { setCart } from '../state/actions/cart'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { toast } from 'react-toastify'
import FormatCurrency from '../utils/FormatCurrency'
import { useMouseOverZoom } from '../utils/Zoom'
import ProductReviews from '../components/ProductReviews'

const ProductDetails: React.FC = () => {
  const [product, setProduct] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [drawer, setDrawer] = useState('description')
  const [liked, setLiked] = useState<{ state: boolean; _id: string }>({
    _id: '',
    state: false,
  })
  const [bookmarked, setBookmarked] = useState<{ state: boolean; _id: string }>(
    {
      _id: '',
      state: false,
    }
  )
  const [image, setImage] = useState<string>(
    product.length !== 0
      ? product[0].arrimages.images[product[0].arrimages.thumbnail]
      : ''
  )
  const source = useRef<HTMLImageElement>(null)
  const target = useRef<HTMLCanvasElement>(null)
  const cursor = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const dispatch = useAppDispatch()

  const cart: {}[] | undefined = useAppSelector((state) => state.cart)
  const currency: any = useAppSelector((state) => state.currency)

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true)
        const res = await api(
          'GET',
          `products/singleproduct/${window.location.pathname.split('/').pop()}`,
          {},
          { currency: currency.code ? currency.code : 'usd' }
        )
        if (res.status === 200) {
          setProduct(res.data)
        } else {
          console.log(res)
          toast('Product not Found! broken Link')
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetchProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })
  useEffect(() => {
    if (product.length !== 0) {
      setImage(product[0].arrimages.images[product[0].arrimages.thumbnail])
    }
  }, [product])

  const isSticky = () => {
    const header = document.querySelector('.product_details_image_wrapper')
    const scrollTop = window.scrollY
    scrollTop >= 80
      ? header?.classList.add('product_details_left_Sticky')
      : header?.classList.remove('product_details_left_Sticky')
  }

  useMouseOverZoom(source, target, cursor)

  return (
    <Fragment>
      {product.length !== 0 ? (
        <div>
          <ProdMeta
            title={product[0].title}
            description={product[0].description}
            prodimage={
              product[0].arrimages.images[product[0].arrimages.thumbnail]
            }
            revised={new Intl.DateTimeFormat('en-US', {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }).format(new Date(product[0].updatedAt))}
          />
        </div>
      ) : (
        ''
      )}
      <Header />
      {loading ? (
        <div className="product_details">
          <div className="product_details_container">
            <div className="product_details_wrapper">
              <div className="product_details_left">
                <div
                  className="product_details_image_wrapper c-skeleton-square"
                  style={{ borderRadius: '12px' }}
                ></div>
              </div>
              <div className="product_details_right">
                <div className="product_details_r_left">
                  <div className="product_details_r_left_top">
                    <div
                      className="product_details_title c-skeleton-square"
                      style={{
                        borderRadius: '12px',
                        height: '30px',
                        width: '100%',
                      }}
                    ></div>
                    <div
                      className="product_details_title c-skeleton-square"
                      style={{
                        borderRadius: '12px',
                        height: '20px',
                        width: '200px',
                        marginRight: '20px',
                      }}
                    ></div>
                    <div
                      className="product_details_title c-skeleton-square"
                      style={{
                        borderRadius: '12px',
                        height: '20px',
                        width: '200px',
                      }}
                    ></div>
                    <div
                      className="product_details_title c-skeleton-square"
                      style={{
                        borderRadius: '12px',
                        height: '200px',
                        width: '100%',
                      }}
                    ></div>
                    <div
                      className="product_details_title c-skeleton-square"
                      style={{
                        borderRadius: '12px',
                        height: '50px',
                        width: '100%',
                      }}
                    ></div>
                  </div>
                  <div className="product_details_r_left_bottom c-skeleton-square"></div>
                </div>
                <div
                  className="product_details_r_right c-skeleton-square"
                  style={{
                    borderRadius: '12px',
                    height: '370px',
                    padding: '0px',
                    border: 'none',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      ) : product.length !== 0 ? (
        <div className="product_details">
          <div className="product_details_container">
            <div className="product_details_wrapper">
              <div className="product_details_left">
                <div className="product_details_image_wrapper product_details_left_Sticky">
                  <div className="product_details_image_sidebar">
                    {product[0].arrimages.images.map((i: string) => {
                      return (
                        <div
                          className="product_details_item_image"
                          key={i}
                          onClick={() => setImage(i)}
                        >
                          <img
                            src={i}
                            alt=""
                            className="product_details_highlighted_item_image"
                          />
                        </div>
                      )
                    })}
                  </div>
                  <div className="product_details_image_highlight">
                    <img
                      ref={source}
                      src={image}
                      alt=""
                      className="product_details_highlighted_image"
                    />
                    <div ref={cursor} className="product_highlight_cursor" />
                    <canvas
                      ref={target}
                      className="product_highlight_zoom_canvas"
                    ></canvas>
                    <div className="product_highlight_meta">
                      <div className="product_highlight_meta_left">
                        {liked.state ? (
                          <SolidHeart
                            className="likeheart"
                            onClick={() => {
                              setLiked({ _id: '', state: false })
                            }}
                          />
                        ) : (
                          <StrokeHeart
                            className="likeheart"
                            onClick={() => {
                              setLiked({ _id: product[0]._id, state: true })
                            }}
                          />
                        )}
                        <span>20.5k likes</span>
                      </div>
                      <div className="product_highlight_meta_right">
                        {bookmarked.state ? (
                          <SolidBookmark
                            className="bookmark_product"
                            onClick={() => {
                              setBookmarked({ _id: '', state: false })
                            }}
                            title="bookmarked"
                          />
                        ) : (
                          <BookMark
                            className="bookmark_product"
                            onClick={() => {
                              setBookmarked({
                                _id: product[0]._id,
                                state: true,
                              })
                            }}
                            title="bookmark"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product_details_right">
                <div className="product_details_r_left">
                  <div className="product_details_r_left_top">
                    <h1 className="product_details_title">
                      {product[0].title}
                    </h1>
                    <div className="product_details_r_left_top_ratings">
                      <Ratings rating={4.2} />
                    </div>
                  </div>
                  <div className="product_details_r_left_middle">
                    <div className="product_details_r_middle_prices_wrap">
                      <div className="product_details_r_middle_price_t">
                        <p className="product_details_r_middle_price">
                          {product[0].price ? (
                            Array.isArray(product[0].price) &&
                            product[0].price.length > 0 ? (
                              product[0].price.length !== 1 ? (
                                <span>
                                  <FormatCurrency
                                    value={parseFloat(product[0].price[0])}
                                  />
                                  <span style={{ margin: '0 10px' }}>-</span>
                                  <FormatCurrency
                                    value={parseFloat(product[0].price[1])}
                                  />
                                </span>
                              ) : (
                                <FormatCurrency
                                  value={parseFloat(product[0].price[0])}
                                />
                              )
                            ) : (
                              '---'
                            )
                          ) : (
                            '---'
                          )}
                        </p>
                        {product[0].previous_price && (
                          <p className="product_details_r_left_middle_discount">
                            <FormatCurrency value={product[0].previous_price} />
                          </p>
                        )}
                      </div>
                      {product[0].previous_price && (
                        <div className="product_details_r_left_middle_discount_percentage">
                          <h2 className="percentage_discount">{`-${
                            ((parseFloat(product[0].previous_price) -
                              parseFloat(product[0].price[0])) /
                              parseFloat(product[0].previous_price)) *
                            100
                          }%`}</h2>
                        </div>
                      )}
                    </div>
                    <div className="product_details_r_middle_actions">
                      <button
                        className="product_details_r_middle_action_btn"
                        onClick={() => {
                          if (Array.isArray(cart)) {
                            if (
                              !cart.some(
                                (item: any) => item._id === product[0]._id
                              )
                            ) {
                              dispatch(
                                setCart([
                                  ...cart,
                                  { ...product[0], quantity: 1 },
                                ])
                              )
                            } else {
                              toast('Product added!', {
                                toastId: product[0]._id,
                              })
                            }
                          } else {
                            dispatch(setCart([{ ...product[0], quantity: 1 }]))
                          }
                        }}
                      >
                        add to cart
                      </button>
                      <button className="product_details_r_middle_action_btn">
                        buy now
                      </button>
                    </div>
                    <p className="product_details_r_middle_shipping">
                      <span>
                        <FormatCurrency value={1200} />
                      </span>{' '}
                      Shipping fees to your Area
                    </p>
                  </div>
                  <div className="products_details_meta">
                    <ul>
                      <li>
                        <strong>Seller:</strong> Apple
                      </li>
                      <li>
                        <strong>Weight:</strong> 250g
                      </li>
                      <li>
                        <strong>Brand:</strong> Apple
                      </li>
                      <li>
                        <strong>Color:</strong> Light Gray
                      </li>
                      <li>
                        <strong>Model:</strong> Apple
                      </li>
                      <li>
                        <strong>Condition:</strong> Brand New
                      </li>
                    </ul>
                  </div>
                  <br />
                </div>
                <div className="product_details_r_right"></div>
              </div>
            </div>
            <div className="product_informatics_drawer">
              <div className="prod_info_drawer_header">
                <div
                  className={`prod_drawer_item ${
                    drawer === 'description' ? 'prod_drawer_item_active' : ''
                  }`}
                  onClick={() => {
                    setDrawer('description')
                  }}
                >
                  <h3>Description</h3>
                </div>
                <div
                  className={`prod_drawer_item ${
                    drawer === 'reviews' ? 'prod_drawer_item_active' : ''
                  }`}
                  onClick={() => {
                    setDrawer('reviews')
                  }}
                >
                  <h3>Ratings & Reviews (200k)</h3>
                </div>
              </div>
              <div className="prod_info_drawer_body"></div>
            </div>

            {drawer === 'description' && (
              <div className="product_details_r_left_bottom">
                <div className={`text full-text`}>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      table: (props) => (
                        <table
                          style={{
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                          }}
                        >
                          {props.children}
                        </table>
                      ),
                    }}
                  >
                    {product[0].description}
                  </ReactMarkdown>
                </div>
              </div>
            )}
            {drawer === 'reviews' && <ProductReviews />}
          </div>
        </div>
      ) : (
        <p>We could't find the product you are Looking for!</p>
      )}
      <Footer />
    </Fragment>
  )
}

export default ProductDetails
