import { useEffect, useState } from 'react'
import api from '../api/axios'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import { setExchange } from '../state/actions/exchange'

const FormatCurrency = ({ value }: { value: number }) => {
  const currency: any = useAppSelector((state) => state.currency)
  const exchange: any = useAppSelector((state) => state.exchange)
  const [roundedValue, setRoundedValue] = useState<number>(0)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchExchange = async () => {
      console.log('Fetching Exchangev from format currency')
      const res = await api('GET', 'exchange/all')
      if (res.status === 200) {
        dispatch(setExchange(res.data))
      }
    }

    if (exchange.length === 0) {
      fetchExchange()
    }
    const exchange_rate: number = exchange.find((curr: any) => {
      return curr.currency_code === currency.code
    })?.exchange_rate_to_usd

    setRoundedValue(parseFloat((value * exchange_rate).toFixed(2)))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, value])

  const formattedValue = (
    roundedValue && roundedValue !== 0 ? roundedValue : 0
  ).toLocaleString('en-US', {
    style: 'currency',
    currency: currency.code ? currency.code : 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return <span>{formattedValue}</span>
}

export default FormatCurrency
