import React, { useEffect, useState } from 'react'
import '../styles/css/productreviews.css'
import Kibet from '../assets/images/kibet.png'
import { ReactComponent as EllipsisVertical } from '../assets/svg/ellipsis-vertical.svg'
import { ReactComponent as Flag } from '../assets/svg/flag.svg'
import { useAppSelector } from '../state/hooks'

const ProductReviews = () => {
  const [flag, setFlag] = useState<number | null>(null)
  const itemsArray = Array.from({ length: 20 }, (_, index) => index + 1)
  const islogged = useAppSelector((state) => state.logged)
  const [canreview, setCanReview] = useState(false)

  useEffect(() => {
    console.log(islogged)
    if (islogged) {
      setCanReview(true)
    } else {
      setCanReview(false)
    }
  }, [islogged])
  return (
    <div
      className="product_review"
      onClick={() => {
        if (flag) {
          setFlag(null)
        }
      }}
    >
      {canreview && (
        <div className="prod_write_a_review" id="write-a-review">
          <div className="prod_write_review_wrapper">
            <form className="prod_write_review_form">
              <div className="write_prod_rev_form_group">
                <label htmlFor="review">
                  Write a Review <span>*</span>
                </label>
                <br />
                <textarea
                  name="review"
                  id=""
                  className="review_prod_input"
                ></textarea>
              </div>
              <button type="submit" className="prod_post_review_btn">
                post
              </button>
            </form>
          </div>
        </div>
      )}
      <h2 className="prod_previews_title">Ratings and Reviews</h2>
      <div className="product_reviews_container">
        {itemsArray.map((item, index: number) => {
          return (
            <div className="prod_review_item" key={index}>
              <div className="prod_review_header">
                <div className="prod_review_header_left">
                  <div className="prod_reviewer_profile">
                    <div className="prod_reviewer_image">
                      <img src={Kibet} alt="" />
                    </div>
                    <h3 className="prod_reviewer_name">Dennis Kibet</h3>
                    <span
                      style={{
                        padding: '3px',
                        backgroundColor: '#ff4209',
                        borderRadius: '5px',
                        fontSize: '0.6rem',
                        color: '#fff',
                        cursor: 'pointer',
                      }}
                    >
                      verified
                    </span>
                  </div>
                  <div className="review_prod_meta">
                    <i data-star={Math.floor(Math.random() * 5) + 1}></i>
                    <span className="review_prod_date">April 20, 2024</span>
                  </div>
                </div>
                <div className="prod_review_header_right">
                  <EllipsisVertical
                    className="ellipsis_vertical_review_more"
                    onClick={() => {
                      setFlag(flag && flag === index ? null : index)
                    }}
                  />
                  {flag && flag === index ? (
                    <div className="prod_review_more_options">
                      <div
                        className="prod_review_more_option_item"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <Flag className="prod_review_flag" />
                        <span>Flag as inappropriate</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="prod_review_body">
                <p className="prod_preview_text">
                  Hi, long time fan of your services. Just a few things I think
                  could be better, in my opinion. 1. The sambaza internet option
                  is 5-10mb. How's this possible?? Maybe it's a bug somewhere?
                  Thanks 2. The interface feels off but I guess it will take
                  time to get used to it. The layout feels congested in a way.
                  Previous app had a distinction. Like blaze tariff and the
                  other kind. That interface was dope. Maybe see what can be
                  carried forward and make the app feel familiar. Thanks
                </p>
                <p className="no_of_people_helped">
                  572 people found this review helpful
                </p>
                <div className="prod_review_is_helpful">
                  <p>Did you find this helpful?</p>
                  <div className="review_helpful_choices">
                    <span className="review_helpful_choice">Yes</span>
                    <span className="review_helpful_choice">No</span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProductReviews
