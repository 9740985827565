import countries from '../data/countries.json'
import currencies from '../data/common_currency.json'
import countrycurr from '../data/country_currency.json'

interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface Address {
  country: string | null
  administrative_area: string | null
  locality: string | null
  sublocality: string | null
  route: string | null
}

function getAddressComponents(a: any, i: number): Address {
  const components: AddressComponent[] = a.results[i].address_components

  const address: Address = {
    country: null,
    administrative_area: null,
    locality: null,
    sublocality: null,
    route: null,
  }

  components.forEach((component) => {
    component.types.forEach((type) => {
      switch (type) {
        case 'country':
          address.country = component.long_name
          break
        case 'administrative_area_level_1':
          address.administrative_area = component.long_name
          break
        case 'locality':
          address.locality = component.long_name
          break
        case 'sublocality':
        case 'sublocality_level_1':
          address.sublocality = component.long_name
          break
        case 'route':
          address.route = component.long_name
          break
        default:
          break
      }
    })
  })

  return address
}

export const navigatorGetUserLocation = (): Promise<{
  country: any[]
  currency: any[]
}> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            let address: Address = {
              country: null,
              administrative_area: null,
              locality: null,
              sublocality: null,
              route: null,
            }
            for (let i = 0; i < data.results.length; i++) {
              const addresslookup: Address = getAddressComponents(data, i)
              if (addresslookup.sublocality) {
                address = addresslookup
                break
              }
            }
            const country = countries.find(
              (obj) => obj.name === address.country
            )
            const curr = countrycurr.find(
              (obj) => obj.country === country?.name
            )
            const currency = currencies.find(
              (obj) => obj.code === curr?.currency_code
            )

            resolve({
              country: [
                {
                  ...country,
                  city: address.locality ? address.locality : '',
                  administrative_area: address.administrative_area
                    ? address.administrative_area
                    : '',
                  sublocality: address.sublocality ? address.sublocality : '',
                  route: address.route ? address.route : '',
                },
              ],
              currency: [currency],
            })
          })
          .catch((error) => {
            console.error('Error fetching location:', error)
            reject(error)
          })
      },
      (error) => {
        console.error('Error getting user location:', error)
        reject(error)
      }
    )
  })
}
